import {
  allFilterDefaults,
  bookingPropertyFilterDefaults,
  longTermFilterDefaults,
} from "../../store/property";

const _isEmpty = (data) => {
  if (data === "") return null;
  if (!Array.isArray(data) || !data) return data;
  if (data.length === 2) {
    if (data[0] === 0 && data[1] === 0) return null;
    else if (typeof data[0] === "undefined" && data[1]) {
      return [0, data[1]];
    } else {
      let filtered = data.filter((el) => typeof el !== "undefined" && el !== 0);
      return filtered.length === 0 ? null : data;
    }
  } else if (data.length === 1 && data[0] === 0) {
    return null;
  } else return data;
};

/* eslint-disable */
export default function getPropertyData(data) {
  let obj = {};
  let type = null;
  switch (data.listType) {
    case "1":
      type = allFilterDefaults;
      break;
    case "2":
      type = bookingPropertyFilterDefaults;
      break;
    case "3":
      type = longTermFilterDefaults;
      break;
    default:
      type = allFilterDefaults;
  }
  if (type !== null) {
    Object.keys(type).map((ele) => {
      if (ele === "location" || ele === "duration") {
        obj[ele] = data[ele];
      } else {
        obj[ele] = _isEmpty(data[ele]);
      }
    });
  }
  return obj;
}
