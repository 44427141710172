import React from "react";
import { addContactGQL } from "../../store/person/contact";
import { getClient } from "../../store/auth";
import { ToastContainer } from "react-toastify";
import { successToast, errorToast } from "../../components/Toaster";
import * as Yup from "yup";
import mixpanel from "mixpanel-browser";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./Newsletter.scss";
import { CONTACT } from "../../utils/constants";

const contactClient = getClient(CONTACT);
function addToContact(e) {
  mixpanel.track("Marketing Page Action", {
    ndbId: "0",
    sub: "Sign Up for Mailing List",
  });
  try {
    contactClient
      .mutate({
        mutation: addContactGQL,
        variables: {
          contact: {
            email: e.email,
          },
        },
      })
      .then((res) => {
        if (
          res.data &&
          res.data.addContact &&
          res.data.addContact.response &&
          res.data.addContact.response.includes("already")
        ) {
          errorToast("Well it seems you are already part of our mailing list!");
        } else {
          return successToast(
            "Thanks! You have been added to the newsletter mailing list!"
          );
        }
      })
      .catch((error) => {
        const Error = error.toString();
        console.log(Error);
        return (
          // document.getElementById("email").reset(),
          errorToast("Failed to Sign up: " + Error.split("error:")[1])
        );
      });
  } catch (e) {}
}
const Newsletter = () => {
  return (
    <>
      <ToastContainer />
      <section class="bottom-form-section">
        {/* Download Apps section */}
        <div class="download-apps">
          <div class="downlaod-card-wrap">
            <div class="card-left">
              <div class="card-title">Download our app</div>
              <div class="card-subtitle">
                Find out how other REM users are taking advantage of this
                incredible network of partners and services available.
              </div>
              <div class="d-flex">
                <a
                  href="https://apps.apple.com/us/app/leasera/id1544687224"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="mr-3"
                >
                  <img
                    src="assets/img/marketing/appstore-img.jpg"
                    alt="appstore"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.leasera.leasera"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/marketing/playstore-img.jpg"
                    alt="playstore"
                  />
                </a>
              </div>
            </div>
            <div class="card-right">
              <img
                src="assets/img/marketing/download-banner-1.png"
                alt="app"
                class="mr-3"
              />
              <img src="assets/img/marketing/download-banner-2.png" alt="app" />
            </div>
          </div>
        </div>
        <div class="container">
          <div class="text-container">
            <h3>Join REM and become part of a new life on Leasing.</h3>
            <h4>
              We can't wait to start appreciating, strengthening and building
              access to the places, services and lifestyle you love. Isn't it
              time for more?
            </h4>
            <Formik
              initialValues={{ email: "" }}
              onSubmit={(values, actions) => {
                addToContact(values);
                actions.resetForm();
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email().required("Required"),
              })}
              render={({
                values,
                setFieldValue,
                errors,
                touched,
                handleReset,
                handleSubmit,
              }) => (
                <Form
                  textAlign="center"
                  class="input-with-button"
                  id="contactForm"
                >
                  <div className="form-group">
                    <Field
                      class={`form-control ${errors.email && "is-invalid"}`}
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Please enter your email address..."
                    />
                    <ErrorMessage
                      className="invalid-feedback"
                      name="email"
                      component="div"
                    />
                  </div>
                  <button
                    type="submit"
                    class="btn btn-dark"
                    onClick={handleSubmit}
                  >
                    Sign Up Now
                  </button>
                </Form>
              )}
            />

            {/* <form onSubmit={(e) =>addToContact(e)} class="input-with-button" id="contactForm">
                        <input type="email" class="form-control" id="newContact" placeholder="Enter your email address" />
                        <button type="submit" class="btn btn-dark" onClick={handleReset}>Sign Up Now</button>
                    </form> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Newsletter;
