import React from "react";
import DatePicker from "react-datepicker";
import { Form } from "semantic-ui-react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerContainer = ({ values, onChangeField }) => {
  const getSelectedDate = (minDate, maxDate) => {
    if (moment(minDate).toDate() > moment(maxDate).toDate()) {
      onChangeField(`duration[1]`, moment(minDate).add(1, "days"));
      return moment(minDate).add(1, "days").toDate();
    }
    return moment(maxDate).toDate();
  };
  return (
    <>
      <Form.Field>
        <div className="search-with-icon">
          <img
            src="assets\img\search-icons\icon-calendar.svg"
            className="input-icon"
          />
          <DatePicker
            selected={moment(values.duration[0]).toDate()}
            onChange={(date) => onChangeField(`duration[0]`, date)}
            selectsStart
            onKeyDown={(e) => e.preventDefault()}
            minDate={new Date()}
            startDate={moment(values.duration[0]).toDate()}
            endDate={moment(values.duration[1]).toDate()}
            dateFormat="MMM dd"
            className="form-control"
          />
        </div>
      </Form.Field>
      <Form.Field>
        <div className="search-with-icon">
          <img
            src="assets\img\search-icons\icon-calendar.svg"
            className="input-icon"
          />
          <DatePicker
            minDate={moment(values.duration[0]).toDate()}
            selected={getSelectedDate(values.duration[0], values.duration[1])}
            onChange={(date) => onChangeField(`duration[1]`, date)}
            selectsEnd
            onKeyDown={(e) => e.preventDefault()}
            startDate={moment(values.duration[0]).toDate()}
            endDate={moment(values.duration[1]).toDate()}
            dateFormat="MMM dd"
            className="form-control"
          />
        </div>
      </Form.Field>
    </>
  );
};

export default DatePickerContainer;
