import React from "react";
import { withApollo } from "react-apollo";

function ForgotPassworSuccess(props) {
  const closeModal = () => {
    document.getElementById("forgot-password-1").remove();
  };

  return (
    <div
      class="modal fade show"
      id="forgot-password-1"
      tabindex="-1"
      role="dialog"
      aria-labelledby="forgotPasswordModal"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content success-modal">
          <div class="modal-header">
            <div class="success-image">
              <img
                src="assets/img/marketing/check-icon.svg"
                alt="Success icon"
              />
            </div>
            <h5 class="modal-title" id="forgotPasswordModalLabel">
              Email sent successfully!
            </h5>
            <p class="modal-sutitle">
              To get back into your account, follow the instructions we’ve sent
              to your provided email address.{" "}
            </p>
            <button
              type="submit"
              class="btn btn-block btn-color"
              onClick={() => closeModal()}
            >
              Back to Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withApollo(ForgotPassworSuccess);
