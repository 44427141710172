import React from "react";
import { ErrorMessage, useField } from "formik";

export const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const placeholderText = () => {
    switch (field.name) {
      case "firstName":
        return "First Name";
      case "lastName":
        return "Last Name";
      case "phoneNumber":
        return "(888)-888-8888";
      default:
        return (
          field.name.slice(0, 1).toUpperCase() +
          field.name.slice(1).toLowerCase()
        );
    }
  };

  return (
    <div className="has-validation">
      <div
        className={`rld-single-input ${
          meta.touched && meta.error && "form-error-input"
        }`}
      >
        <input
          {...field}
          {...props}
          type="text"
          placeholder={placeholderText()}
        />
      </div>

      <ErrorMessage
        component="div"
        name={field.name}
        className="form-error-message"
      />
    </div>
  );
};
