import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Header from "../Header";
import { useAuth } from "../../containers/Auth";
import { ROUTES } from "../../utils";
import FooterMain from "../../components/Footer/FooterMain";
import Testimonial from "../Testimonial";
import Newsletter from "../Newsletter";
import Slider from "react-slick";
import mixpanel from "mixpanel-browser";
import ReactPlayer from "react-player/youtube";
const HeaderContainer = ({ children, isHidden }) => {
  const { auth } = useAuth();
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // const url = URL.createObjectURL(video)
  return (
    <>
      <Header isHidden={isHidden} auth={auth} />
      <div class="home-banner row">
        <div class="container-fluid">
          {/* <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel"> */}
          {/* <ol class="carousel-indicators">
              <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
            </ol> */}
          <div class="carousel-inner">
            <Slider {...settings}>
              <div class="carousel-item active">
                <img
                  src="assets/img/Landing/banner-1.jpg"
                  class="carousel-bg-img"
                  alt="banner"
                />
                <div class="container d-flex align-items-center">
                  <div class="carousel-caption">
                    <div class="carousel-caption-wrap">
                      <h5>Welcome to a new era of leasing.</h5>
                      <p>
                        The only fully unified rental ecosystem in the world.
                        Rentals that cover a day, a decade and the seamless
                        services that make these properties amazing.{" "}
                      </p>
                      <div class="carousel-btns">
                        <a href="/sign-up-form" class="homebtn">
                          Start a new life on leasing today!
                        </a>
                        <a
                          href="/marketing-demo"
                          class="demobtn"
                          onClick={() =>
                            mixpanel.track("Listing Action", {
                              ndbId: "0",
                              sub: "Request Demo Button",
                            })
                          }
                        >
                          Request Demo
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <img
                  src="assets/img/Landing/banner-2.jpg"
                  class="carousel-bg-img"
                  alt="banner"
                />
                <div class="container d-flex align-items-center">
                  <div class="carousel-caption">
                    <div class="carousel-caption-wrap">
                      <h5>
                        Property Managers are creating unmatched revenues &amp;
                        scale with the REM Leasing platform.
                      </h5>
                      <p>
                        The most comprehensive management software for single
                        and multifamily portfolio operators ever. Automate
                        operations, build better partnerships and empower loyal
                        customers like never before.
                      </p>
                      <div class="carousel-btns">
                        <a href="/sign-up-form" class="homebtn">
                          Offer a new life on leasing today!
                        </a>
                        <a
                          href="/marketing-demo"
                          class="demobtn"
                          onClick={() =>
                            mixpanel.track("Listing Action", {
                              ndbId: "0",
                              sub: "Request Demo Button",
                            })
                          }
                        >
                          Request Demo
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <img
                  src="assets/img/Landing/banner-3.jpg"
                  class="carousel-bg-img"
                  alt="banner"
                />
                <div class="container d-flex align-items-center">
                  <div class="carousel-caption">
                    <div class="carousel-caption-wrap">
                      <h5>
                        Renters, with REM Loyalty you'll finally be
                        appreciated and have access to so much more.
                      </h5>
                      <p>
                        Get more than a roof over your head. Access anything
                        from hotels to homes, incredible services, durations
                        from a day to decade and be appreciated for all of it.
                      </p>
                      <div class="carousel-btns">
                        <a href="/sign-up-form" class="homebtn">
                          Start a new life on leasing today!
                        </a>
                        <a
                          href="/marketing-demo"
                          class="demobtn"
                          onClick={() =>
                            mixpanel.track("Listing Action", {
                              ndbId: "0",
                              sub: "Request Demo Button",
                            })
                          }
                        >
                          Request Demo
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <img
                  src="assets/img/Landing/banner-4.jpg"
                  class="carousel-bg-img"
                  alt="banner"
                />
                <div class="container d-flex align-items-center">
                  <div class="carousel-caption">
                    <div class="carousel-caption-wrap">
                      <h5>
                        Service Professionals, tap into the future through out
                        unified rental marketplace
                      </h5>
                      <p>
                        We believe in a virtuous ecosystem where everyone does
                        their part by doing what they do best. Whether you're
                        delivering food or repairing credit, our unified
                        ecosystem allows you to do just that.
                      </p>
                      <div class="carousel-btns">
                        <a href="/sign-up-form" class="homebtn">
                          Partner with the REM Loyalty marketplace today!
                        </a>
                        <a
                          href="/marketing-demo"
                          class="demobtn"
                          onClick={() =>
                            mixpanel.track("Listing Action", {
                              ndbId: "0",
                              sub: "Request Demo Button",
                            })
                          }
                        >
                          Request Demo
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
            {/* </div> */}
            <div class="points-card-wrap">
              <img src="assets/img/points-icon.svg" alt="points" />
              <div class="points-title">Get 500 Loyalty Points</div>
              <div class="points-subtitle">
                Let us help you start your journey on REM.{" "}
              </div>
              <a href="/sign-up-form" class="btn-white mt-3">
                Sign Up Now
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <section class="home-carousel">
        <div class="container">
          <div class="carousel-listing-wrap">
            <div class="listing-card">
              <div class="listing-card-top">
                <div class="listing-card-img">
                  <img src="https://d27p8o2qkwv41j.cloudfront.net/wp-content/uploads/2016/07/shutterstock_262923179-e1500871070126.jpg" alt="property image" />
                </div>
              </div>
              <div class="listing-card-bottom">
                <div class="listing-title">Cascade Meadow Apartments</div>
                <div class="listing-subtitle">11311 19th Ave SE, everett, Washington DC, 98208, US</div>
                <div class="listing-info-row">
                  <div class="listing-info-col">1+ Beds</div>
                  <span class="dot-divider"></span>
                  <div class="listing-info-col">1+ Baths</div>
                  <span class="dot-divider"></span>
                  <div class="listing-info-col">1017 sq.ft</div>
                </div>
              </div>
            </div>
            <div class="listing-card">
              <div class="listing-card-top">
                <div class="listing-card-img">
                  <img src="https://d27p8o2qkwv41j.cloudfront.net/wp-content/uploads/2016/07/shutterstock_262923179-e1500871070126.jpg" alt="property image" />
                </div>
              </div>
              <div class="listing-card-bottom">
                <div class="listing-title">Cascade Meadow Apartments</div>
                <div class="listing-subtitle">11311 19th Ave SE, everett, Washington DC, 98208, US</div>
                <div class="listing-info-row">
                  <div class="listing-info-col">1+ Beds</div>
                  <span class="dot-divider"></span>
                  <div class="listing-info-col">1+ Baths</div>
                  <span class="dot-divider"></span>
                  <div class="listing-info-col">1017 sq.ft</div>
                </div>
              </div>
            </div>
            <div class="listing-card">
              <div class="listing-card-top">
                <div class="listing-card-img">
                  <img src="https://d27p8o2qkwv41j.cloudfront.net/wp-content/uploads/2016/07/shutterstock_262923179-e1500871070126.jpg" alt="property image" />
                </div>
              </div>
              <div class="listing-card-bottom">
                <div class="listing-title">Cascade Meadow Apartments</div>
                <div class="listing-subtitle">11311 19th Ave SE, everett, Washington DC, 98208, US</div>
                <div class="listing-info-row">
                  <div class="listing-info-col">1+ Beds</div>
                  <span class="dot-divider"></span>
                  <div class="listing-info-col">1+ Baths</div>
                  <span class="dot-divider"></span>
                  <div class="listing-info-col">1017 sq.ft</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section class="home-widgets">
        <div class="container">
          <div class="row">
            <div class="col-6 col-lg-3 widget-col">
              <div class="widget-image">
                <img
                  src="assets/img/marketing/icons-home.svg"
                  alt="icon home"
                />
              </div>
              <div class="widget-content">
                <h4>1.4M+</h4>
                <p>Listings Available</p>
              </div>
            </div>
            <div class="col-6 col-lg-3 widget-col">
              <div class="widget-image">
                <img
                  src="assets/img/marketing/icons-money.svg"
                  alt="icon home"
                />
              </div>
              <div class="widget-content">
                <h4>$2,300+</h4>
                <p>Average renter savings</p>
              </div>
            </div>
            <div class="col-6 col-lg-3 widget-col">
              <div class="widget-image">
                <img
                  src="assets/img/marketing/icons-residents.svg"
                  alt="icon home"
                />
              </div>
              <div class="widget-content">
                <h4>13K+</h4>
                <p>Visitors each month</p>
              </div>
            </div>
            <div class="col-6 col-lg-3 widget-col">
              <div class="widget-image">
                <img
                  src="assets/img/marketing/icons-badge.svg"
                  alt="icon home"
                />
              </div>
              <div class="widget-content">
                <h4>#1</h4>
                <p>flexible rental options</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="half-image-section home-image-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h3>Get to know REM! </h3>
              <h4 class="heading4">
                Finally, an actual single source for properties, services and
                market appreciation that gets more powerful each day.
              </h4>
              <p class="text desktop-text">
                Property management software needs to meet the demands of the
                tech-savvy rental market of today. Luckily, we now have the
                tools to do so. REM is a singular platform that connects and
                balances property management, service providers, and renters
                alike; empowering rental communities everywhere, together, as
                one, virtuous ecosystem.
              </p>
              <p class="text mobile-text">
              REM is a singular platform that connects and balances
                property management, service providers, and renters alike;
              </p>
              <div class="mt-5">
                <NavLink exact to={ROUTES.ABOUT_US} class="homebtn">
                  About REM
                </NavLink>
              </div>
            </div>
            <div class="col-md-6">
              <div class="image-container">
                <img
                  src="assets/img/marketing/homepage-house.svg"
                  alt="Illustration"
                  class="fixed-left-image"
                />
                <img
                  src="assets/img/marketing/home_image_1.jpg"
                  alt="Plumber"
                  class="main-image"
                />
                <img
                  src="assets/img/marketing/square_circles.png"
                  alt="Dots"
                  class="fixed-square-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="home-marketplace">
          <img
            src="assets/img/marketing/home_image_2.jpg"
            alt=""
            class="home-marketplace-banner"
          />
          <img
            src="assets/img/marketing/square_circles.png"
            alt="square circle"
            class="home-marketplace-watermark"
          />
          <div class="home-markeplaceContent">
            <div class="container">
              <div
                id="carouselExampleCaptions2"
                class="carousel slide"
                data-ride="carousel2"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="carousel-caption">
                      <p>A Marketplace of Value</p>
                      <p>Win the hearts of more consumers.</p>
                      <p> Widen your funnel through multifamily. </p>
                    </div>
                  </div>
                  {/* <div class="carousel-item">
                  <div class="carousel-caption">
                    <p>A Marketplace of Value</p>
                    <p>Win the hearts of more consumers.</p>
                    <p> Widen your funnel through multifamily. </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="carousel-caption">
                    <p>A Marketplace of Value</p>
                    <p>Win the hearts of more consumers.</p>
                    <p> Widen your funnel through multifamily. </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="carousel-caption">
                    <p>A Marketplace of Value</p>
                    <p>Win the hearts of more consumers.</p>
                    <p> Widen your funnel through multifamily. </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="carousel-caption">
                    <p>A Marketplace of Value</p>
                    <p>Win the hearts of more consumers.</p>
                    <p> Widen your funnel through multifamily. </p>
                  </div>
                </div> */}
                </div>
                {/* <ol class="carousel-indicators">
                <li data-target="#carouselExampleCaptions2" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleCaptions2" data-slide-to="1"></li>
                <li data-target="#carouselExampleCaptions2" data-slide-to="2"></li>
                <li data-target="#carouselExampleCaptions2" data-slide-to="3"></li>
                <li data-target="#carouselExampleCaptions2" data-slide-to="4"></li>
              </ol> */}
              </div>
              {/* <a class="marketPlatform-btn">View All Platforms</a> */}
            </div>
          </div>
        </div>
        <div class="home-platform">
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-4 home-platform-col">
                <div class="platform-image">
                  <img
                    src="assets/img/marketing/white-icons-home.svg"
                    alt="white-icons-home"
                  />
                </div>
                <div class="platform-content">
                  <h4>Simplified Management</h4>
                  <p>
                    We've analyzed today's most valued tools to give you what
                    you need to run your property efficiently.
                  </p>
                </div>
              </div>
              <div class="col-12 col-lg-4 home-platform-col">
                <div class="platform-image">
                  <img
                    src="assets/img/marketing/white-icons-pet.svg"
                    alt="white-icons-home"
                  />
                </div>
                <div class="platform-content">
                  <h4>Affordable services</h4>
                  <p>
                    We've brought together best in class services to support you
                    and your residents affordably.
                  </p>
                </div>
              </div>
              <div class="col-12 col-lg-4 home-platform-col">
                <div class="platform-image">
                  <img
                    src="assets/img/marketing/white-icons-badge.svg"
                    alt="white-icons-home"
                  />
                </div>
                <div class="platform-content">
                  <h4>Renter Empowerment</h4>
                  <p>
                    We've worked tirelessly to provide access to the services
                    and brands we all love.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="half-image-section renter-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="image-container">
                <img
                  src="assets/img/marketing/homepage-house.svg"
                  alt="Illustration"
                  class="fixed-left-image"
                />
                <img
                  src="assets/img/marketing/home_img_1.png"
                  alt="Plumber"
                  class="main-image"
                />
                <img
                  src="assets/img/marketing/home-img-2.png"
                  alt="Illustration"
                  class="fixed-top-image"
                ></img>
                <img
                  src="assets/img/marketing/square_circles.png"
                  alt="Dots"
                  class="fixed-square-image"
                />
              </div>
            </div>
            <div class="col-md-6">
              <h3>Renters</h3>
              <p class="desktop-text text">
                At REM, you can find the place of your dreams while being
                reassured that our properties are owned and operated by people
                who care about their renters, not just your rent. Build your
                future, directly access services, be rewarded.
              </p>
              <p class="desktop-text text">
                Three days or three years, REM is here to support your
                journey.
              </p>
              <p class="mobile-text text">Find the place of your dreams.</p>
              <p class="mobile-text text">Build your future and be rewarded.</p>
              <p class="mobile-text text">Choose your length of stay.</p>
              <div class="mt-5">
                <NavLink exact to={ROUTES.RENTER} class="homebtn">
                  View more about renter
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="half-image-section propertyOwner-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h3>Property Owners</h3>
              <p class="text">
              REM is your partner, offering the tools, retention & service
                solutions you need for a new era of unified, verified consumers.
                Build loyalty, create access & empower yourselves & your
                renters.
              </p>
              <p class="mobile-text text">
                Three days or three years, REM is here to support your
                journey.
              </p>
              <div class="mt-5">
                <NavLink exact to={ROUTES.MANAGER} class="homebtn">
                  View more about Property Manager
                </NavLink>
              </div>
            </div>
            <div class="col-md-6">
              <div class="image-container">
                <img
                  src="assets/img/marketing/homepage-house.svg"
                  alt="Illustration"
                  class="fixed-left-image"
                />
                <img
                  src="assets/img/marketing/home_img_4.png"
                  alt="Plumber"
                  class="main-image"
                />
                <img
                  src="assets/img/marketing/home_img_3.png"
                  alt="Illustration"
                  class="fixed-top-image"
                ></img>
                <img
                  src="assets/img/marketing/square_circles.png"
                  alt="Dots"
                  class="fixed-square-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="whyLeasera-section">
        <div class="container">
          {/* eslint-disable */}
          <a class="badge-btn">Our Solution</a>
          {/* eslint-enable */}
          <h4 class="desktop-text">Why REM would be your best fit?</h4>
          <h4 class="mobile-text">REM fits your needs!</h4>
          <p>
            We have created a new business model for properties and a new
            lifestyle of empowerment for consumers. New value, new
            opportunities, & an all “new life on leasing”
          </p>
          <div class="whyLeasera-video">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=KXOqDSrSN-s"
              width="100%"
              height="100%"
            />
          </div>
          <div class="whyLeasera-content">
            <div class="whyLeasera-inner">
              <div class="whyLeasera-text">
                <span>"</span>
                The renter becomes more savvy and sophisticated each day. It's
                time our property management tools did the same. Our team has
                solved for new market demands by creating a cooperative platform
                where everyone can benefit whether managing, renting or
                servicing rentals and we couldn't be more excited.
              </div>
              <div class="whyLeasera-image">
                <img src="assets/img/marketing/home_image_4.jpg" alt="home" />
              </div>
            </div>
            <img
              src="assets/img/marketing/square_circles.png"
              alt="Dots"
              class="fixed-square-image"
            />
          </div>
        </div>
      </section>

      {/* Leasera partners scetion */}
      <section class="leasera-partners">
        <div class="container">
          <div class="section-title">As seen in</div>
          <div class="section-subtitle"> </div>
          <div class="partners-list-wrap">
            <div class="partners-list">
              <img src="assets/img/Partners/img-1.png" alt="logo" />
            </div>
            <div class="partners-list">
              <img src="assets/img/Partners/img-2.png" alt="logo" />
            </div>
            <div class="partners-list">
              <img src="assets/img/Partners/img-3.png" alt="logo" />
            </div>
            <div class="partners-list">
              <img src="assets/img/Partners/img-4.png" alt="logo" />
            </div>
            <div class="partners-list">
              <img src="assets/img/Partners/img-5.png" alt="logo" />
            </div>
            <div class="partners-list">
              <img src="assets/img/Partners/img-6.png" alt="logo" />
            </div>
            <div class="partners-list">
              <img src="assets/img/Partners/img-7.png" alt="logo" />
            </div>
            <div class="partners-list">
              <img src="assets/img/Partners/img-8.png" alt="logo" />
            </div>
            <div class="partners-list">
              <img src="assets/img/Partners/img-9.png" alt="logo" />
            </div>
            <div class="partners-list">
              <img src="assets/img/Partners/img-10.png" alt="logo" />
            </div>
            <div class="partners-list">
              <img src="assets/img/Partners/img-11.png" alt="logo" />
            </div>
            <div class="partners-list">
              <img src="assets/img/Partners/img-12.png" alt="logo" />
            </div>
          </div>
        </div>
      </section>
      {/* Download Apps section */}
      {/* <section class="download-apps">
        <div class="downlaod-card-wrap">
          <div class="card-left">
            <div class="card-title">Download our app</div>
            <div class="card-subtitle">Find out how other Leaseran's are taking advantage of this incredible network of partners and services available.</div>
            <div class="d-flex">
              <a href="#" class="mr-3"><img src="assets/img/marketing/appstore-img.jpg" alt="appstore" /></a>
              <a href="#"><img src="assets/img/marketing/playstore-img.jpg" alt="playstore" /></a>
            </div>
          </div>
        </div>
      </section> */}
      <Testimonial />
      <Newsletter />
      <FooterMain />
    </>
  );
};

HeaderContainer.propTypes = {
  children: PropTypes.node,
};

export default HeaderContainer;
