import React, { useEffect } from "react";
import { priceOption, propertyType, MIN, MAX } from "../../utils";

export const getValue = (value) => (value === "" ? 0 : parseInt(value));

export const getTextVal = (value) => (value === 0 ? null : value);

/* eslint-disable */
export const getPriceList = (totalPrices, priceKey) => {
  if (totalPrices && totalPrices[0] && priceKey === MAX)
    return priceOption[priceKey].filter((e) => {
      if (totalPrices[0] < e.value || e.value === null) return e;
    });
  if (totalPrices && totalPrices[1] && priceKey === MIN)
    return priceOption[priceKey].filter((e) => {
      if (totalPrices[1] > e.value || e.value === null) return e;
    });
  return priceOption[priceKey];
};
/* eslint-enable */

export const priceText = (totalPrices) => {
  if (!totalPrices) return null;
  if (
    totalPrices[0] &&
    totalPrices[1] &&
    totalPrices[0] !== 0 &&
    totalPrices[1] !== 0
  )
    return `$${convertPrice(totalPrices[0])}-$${convertPrice(totalPrices[1])}`;
  if (totalPrices[0]) return `$${convertPrice(totalPrices[0])}+`;
  if (totalPrices[1]) return `< $${convertPrice(totalPrices[1])}`;
};

export const getBedText = (totalBeds, status = true) => {
  if (!totalBeds) return null;
  let beds = status ? "Beds" : "";
  if (totalBeds[1] && totalBeds[0] && totalBeds[0] !== 0 && totalBeds[1] !== 0)
    return `${totalBeds[0]} - ${totalBeds[1]} ${beds}`;
  if (totalBeds[0]) return `${totalBeds[0]}+ ${beds}`;
  if (totalBeds[1]) return `Max ${totalBeds[1]} ${beds}`;
};

export const getBathText = (totalBaths, status = true) => {
  if (!totalBaths) return null;
  let Baths = status ? "Baths" : "";
  if (
    totalBaths[1] &&
    totalBaths[0] &&
    totalBaths[0] !== 0 &&
    totalBaths[1] !== 0
  )
    return `${totalBaths[0]} - ${totalBaths[1]} ${Baths}`;
  if (totalBaths[0]) return `${totalBaths[0]}+ ${Baths}`;
  if (totalBaths[1]) return `Max ${totalBaths[1]} ${Baths}`;
};

/* eslint-disable */
export const getBedBath = (totalBeds, key) => {
  if (totalBeds && key === MIN)
    return propertyType[MIN].filter((e) => {
      if (totalBeds > e.value) return e;
    });
  if (totalBeds && key === MAX)
    return propertyType[MAX].filter((e) => {
      if (totalBeds < e.value) return e;
    });
  return propertyType[key];
};
/* eslint-enable */

export const getSizeText = (totalSize) => {
  if (!totalSize) return null;
  if (totalSize[1] && totalSize[0] && totalSize[0] !== 0 && totalSize[1] !== 0)
    return `${totalSize[0]} - ${totalSize[1]}`;
  if (totalSize[0]) return `${totalSize[0]}`;
  if (totalSize[1]) return `Max ${totalSize[1]}`;
};

export const getBedCount = (totalBeds, status = true) => {
  if (!totalBeds) return null;
  const [, min] = totalBeds;
  if (min === 0 || min === 1) return `${min} Bed`;
  if (min > 1) return `1+ Beds`;
  return null;
};

export const getBathCount = (totalBaths, status = true) => {
  if (!totalBaths) return null;
  const [, min] = totalBaths;
  if (min === 0 || min === 1) return `${min} Bath`;
  if (min > 1) return `1+ Baths`;
  return null;
};

export const getSearchResult = (list) => {
  return list.googlePlaceId ? true : false;
};

export const convertPrice = (number, decPlaces = 1) => {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  var abbrev = ["k", "m", "b", "t"];

  // Go through the array backwards, so we do the largest first
  for (var i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    var size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }

  return number;
};

// calls function to close dropdown when clicking outside of div wrapper
export const useOutsideClickDetection = (ref, fn, fnArg) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        fn(fnArg);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};
