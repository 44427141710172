// @flow

const prodConfig = {
  apiKey: 'AIzaSyDgdX5L8P2elnasYa04zFW6MEftdSRLrSc',
  authDomain: 'leasera-staging.firebaseapp.com',
  databaseURL: 'https://leasera-staging.firebaseio.com',
  projectId: 'leasera-staging',
  storageBucket: 'leasera-staging.appspot.com',
  messagingSenderId: '662469587140',
}

// const devConfig = {
//   apiKey: YOUR_API_KEY,
//   authDomain: YOUR_AUTH_DOMAIN,
//   databaseURL: YOUR_DATABASE_URL,
//   projectId: YOUR_PROJECT_ID,
//   storageBucket: '',
//   messagingSenderId: YOUR_MESSAGING_SENDER_ID,
// };

export const config =
  process.env.NODE_ENV === "production" ? prodConfig : prodConfig; // : devConfig;
