import LandingPage from "../containers/LandingPage/LandingPage";
import AboutUs from "../containers/AboutUs/AboutUs";
import ContactUs from "../containers/ContactUs/ContactUs";
import FAQ from "../containers/FAQ/FAQ";
import ForgotPassword from "../containers/ForgotPassword/ForgotPassword";
import ManagerInfo from "../containers/ManagerInfo/ManagerInfo";
import Privacy from "../containers/Privacy/Privacy";
import PropertySearch from "../containers/PropertySearch";
import Rent from "../containers/Rent/Rent";
import RenterInfo from "../containers/RenterInfo/RenterInfo";
import ServiceInfo from "../containers/ServiceInfo/ServiceInfo";
import Terms from "../containers/Terms/Terms";
import RentalAgreement from "../containers/RentalAgreement/RentalAgreement";
import SignUp from "../containers/SignUp/SignUp";
import SignUpForm from "../containers/SignUp/SignUpForm";
import SignInForm from "../containers/SignIn/SignInForm";
import SignUpFormRenter from "../containers/SignUp/SignUpFormRenter";
import Properties from "../containers/Properties";
import PropertyDetail from "../containers/PropertyDetail";
import BookingDetail from "../containers/PropertyDetail/bookingDetail";
import bookingPaymentOne from "../containers/PropertyDetail/bookingPaymentOne";
import bookingPaymentTwo from "../containers/PropertyDetail/bookingPaymentTwo";
import Blog from "../containers/Blog";
import Renter from "../containers/Renter";
import Manager from "../containers/Manager";
import ServiceProvider from "../containers/ServiceProvider";
import PlatformProducts from "../containers/PlatformProducts";
import { ROUTES } from "../utils";
import PetServices from "../containers/PetServices";
import LocalSites from "../containers/LocalSites/LocalSites";
import GroceryShopping from "../containers/GroceryShopping/GroceryShopping";
import MarketingProfessionals from "../containers/MarketingProfessionals/MarketingProfessionals";
import MarketingDemo from "../components/MarketingDemo/MarketingDemo";
import bookingPaymentThree from "../containers/PropertyDetail/bookingPaymentThree";

export const mainRoutes = [
  {
    path: ROUTES.LANDING,
    exact: true,
    component: LandingPage,
  },
  {
    path: ROUTES.ABOUT_US,
    exact: true,
    component: AboutUs,
  },
  {
    path: ROUTES.CONTACT_US,
    exact: true,
    component: ContactUs,
  },
  {
    path: ROUTES.FAQ,
    exact: true,
    component: FAQ,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    exact: true,
    component: ForgotPassword,
  },
  {
    path: ROUTES.MANAGER_INFO,
    exact: true,
    component: ManagerInfo,
  },
  {
    path: ROUTES.PRIVACY,
    exact: true,
    component: Privacy,
  },
  {
    path: ROUTES.PROPERTY_SEARCH,
    exact: true,
    component: PropertySearch,
  },
  {
    path: ROUTES.RENT,
    exact: true,
    component: Rent,
  },
  {
    path: ROUTES.RENTER_INFO,
    exact: true,
    component: RenterInfo,
  },
  {
    path: ROUTES.SERVICE_INFO,
    exact: true,
    component: ServiceInfo,
  },
  {
    path: ROUTES.TERMS,
    exact: true,
    component: Terms,
  },
  {
    path: ROUTES.SIGNUP,
    exact: true,
    component: SignUp,
  },
  {
    path: ROUTES.SIGNUP_FORM_MANAGER,
    exact: true,
    component: SignUpForm,
  },
  {
    path: ROUTES.SIGNUP_FORM_RENTER,
    exact: true,
    component: SignUpFormRenter,
  },
  {
    path: ROUTES.PROPERTIES,
    exact: true,
    component: Properties,
  },
  {
    path: ROUTES.BLOG,
    exact: true,
    component: Blog,
  },
  {
    path: ROUTES.RENTER,
    exact: true,
    component: Renter,
  },
  {
    path: ROUTES.MANAGER,
    exact: true,
    component: Manager,
  },
  {
    path: ROUTES.SERVICE,
    exact: true,
    component: ServiceProvider,
  },
  {
    path: ROUTES.PROPERTY_DETAIL,
    exact: true,
    component: PropertyDetail,
  },
  {
    path: ROUTES.SIGNIN,
    exact: true,
    component: SignInForm,
  },
  {
    path: ROUTES.PLATFORM_PRODUCTS,
    exact: true,
    component: PlatformProducts,
  },
  {
    path: ROUTES.PET_SERVICES,
    exact: true,
    component: PetServices,
  },
  {
    path: ROUTES.MARKETING_PROFESSIONAL,
    exact: true,
    component: MarketingProfessionals,
  },
  {
    path: ROUTES.LOCAL_SITES,
    exact: true,
    component: LocalSites,
  },
  {
    path: ROUTES.GROCERY_SHOPPING,
    exact: true,
    component: GroceryShopping,
  },
  {
    path: ROUTES.RENTAL_AGREEMENT,
    exact: true,
    component: RentalAgreement,
  },
  {
    path: ROUTES.MarketingDemo,
    exact: true,
    component: MarketingDemo,
  },
  {
    path: ROUTES.BOOKING_DETAIL,
    exact: true,
    component: BookingDetail,
  },
  {
    path: ROUTES.BOOKING_PAYMENT_ONE,
    exact: true,
    component: bookingPaymentOne,
  },
  {
    path: ROUTES.BOOKING_PAYMENT_TWO,
    exact: true,
    component: bookingPaymentTwo,
  },
  {
    path: ROUTES.BOOKING_PAYMENT_THREE,
    exact: true,
    component: bookingPaymentThree,
  },
];
