import React from "react";
import Header from "../../components/Header";
import FooterMain from "../../components/Footer/FooterMain";
import Newsletter from "../../components/Newsletter";

const ServiceProvider = () => {
  return (
    <>
      <Header />
      <div class="banner banner-service-provider">
        <div class="container text-center">
          <div class="row">
            <div class="col-12">
              <h1>Service Provider</h1>
              <h4>All your service needs streamlined in one place.</h4>
            </div>
          </div>
        </div>
      </div>
      <section class="half-image-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="badge">What we did</div>
              <h3>
                We believe that handling the needs of both the renter and
                property manager should be so easy{" "}
              </h3>
              <p class="text">
                At REM, we strive to seamlessly integrate service providers
                and their clients. By providing a connection on all aspects of
                the day to day workflow we are better able to let you solve the
                problems at hand.
              </p>
              <p class="text">
                Build your future, directly connect with both renters and
                property managers, and focus on what really matters.
              </p>
            </div>
            <div class="col-md-6">
              <div class="image-container">
                <img
                  src="assets/img/marketing/house.png"
                  alt="Illustration"
                  class="fixed-left-image"
                />
                <img
                  src="assets/img/marketing/sp_image_1.jpg"
                  alt="Plumber"
                  class="main-image"
                />
                <img
                  src="assets/img/marketing/square_circles.png"
                  alt="Dots"
                  class="fixed-square-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="full-back-image-section">
        <div class="container">
          <div class="image-container">
            <h5>Growth potential</h5>
            <h3>
              As a service professional on our platform, you get immediate
              feedback and functionality through your entire work order.{" "}
            </h3>
          </div>
        </div>
      </section>
      <section class="half-image-section benifit-section">
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <h5>Benefits of using REM service</h5>
              <h3>
                We’re constantly developing the next generation of automation &
                solutions
              </h3>
              <p class="text">
                One platform, with the ability to transition to need you may
                have. Access the massive potential of REM all through the
                comfort of one website. No longer will you have to jump to
                various programs to accomplish your daily task list. Whether it
                is painting or repairs, we've got you covered!
              </p>
            </div>
            <div class="col-md-7">
              <div class="image-container">
                <img
                  src="assets/img/marketing/house.png"
                  alt="Illustration"
                  class="fixed-left-image"
                />
                <img
                  src="assets/img/marketing/sp_image_3.jpg"
                  alt="Plumber"
                  class="main-image"
                />
                <img
                  src="assets/img/marketing/square_circles.png"
                  alt="Dots"
                  class="fixed-square-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Newsletter />
      <FooterMain />
      {/* <Segment className="service">
      <div className="service-image">
        <div className="service-text-wrapper">
          <h1>Service Providers</h1>
          <h2>All your service needs streamlined in one place.</h2>
        </div>
      </div>
      <Segment className="service-what-we-did" attached>
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={11}>
                <h1>What we did.</h1>
                <p>
                  At REM, we strive to seamlessly integrate service providers and their clients.  By providing a connection on all aspects of the day to day workflow we are better able to
                  let you solve the problems at hand. Build your future,
                  directly connect with both renters and property managers, and focus on what really matters.
                  </p>
              </Grid.Column>
              <Grid.Column width={5}>
                <Image src={CRAFT} size='large' />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
      <Segment className="service-points" attached>
        <Container>
          <h1>Industry impact.</h1>
          <p>
            At REM we believe that handling the needs of both the renter and property manager should be as easy as possible.  Our goal is to introduce new ways of
            reaching and handling the needs of every day task in a way that lets the service professional get down to what really matters.  By partnering with top tier companies
            around the globe we are better able to streamline everyday communcation and functionality for the service professional at any level.
            Handling service orders and paper work should be simple and less time consuming and our playform works to reflect that philosophy.
                  </p>
          <p>
            The people we partner with, our clients, can finally start focusing on relationships, not transactions.
                  </p>
        </Container>
      </Segment>
      <Segment className="service-benefits" attached>
        <Container>
          <h1>Benefits</h1>
          <Grid>
            <Grid.Row>
              <Grid.Column width={5}>
                <Image src={APARTMENT} size='large' />
              </Grid.Column>
              <Grid.Column width={11}>
                <p>
                  One platform, with the ability to transition to need you may have.
                  Access the massive potential of REM all through the comfort of one website.
                  No longer will you have to jump to various programs to accomplish your daily task list.  Wether it is painting or repairs, we've got you covered!
                  </p>
                <p>
                  We’re constantly developing the next generation of automation & solutions for renters.
                  Our goal is to constantly strive to work for the service professional and enable them to grow with our platform just as we grow for them as well.
                  As a service profesional, your job shouldn't be a burden and by creating dynamic solutions we are taking the stress out of the service industry.
                  </p>
                <p>
                  A variety of accommodations, services & all the tools to manage or live the lifestyle they help create.
                  By partnering with a vast array of services we are constantly unlocking new and innovative ways to break the tired mold.
                  With open ears to beneficial feedback we are better able to custom tailor our platform to your needs.
                  </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment >
      <Segment className="service-dynamic" attached>
        <Container>
          <h1>Growth potential</h1>
          <p>
            As a service professional on our platform, you get immediate feedback and functionality through your entire work order. We’re here to help, starting from
            your first ticket all the way to the final sign off. This gives you advanced, personalized ticket management and prompt client responses. Efficiency and
            cost effective relationships are initiated from the first click. Using our proprietary service management system and analytics, we can use
            extensive historical date to better balance your financial needs towards your client. This
            information will all be privately matched against property data during your search making selection fast, accurate and more cost effective.
                  </p>
          <p>
            Our sophisticated digital approach frees you to focus precisely and exclusively on information that will improve time, energy and financial
            efficiencies. We’ve worked hard to cut unnecessary costs, stress and repeat information requests out of your day so you can function to the best of your ability!
                  </p>
        </Container>
      </Segment>
      <FooterMain />
    </Segment > */}
    </>
  );
};

export default ServiceProvider;
