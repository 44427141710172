import React, { useState, useRef, useEffect } from "react";
import { Dropdown, Form, Checkbox } from "semantic-ui-react";
import { useOutsideClickDetection } from "./helper";
import { getClient } from "../../store/auth";
import {
  getAmenities,
  getLocationType,
} from "../../store/property/properties";
import { SEARCH_PROP } from "../../utils";

function AdditionalFilters({
  onChangeField,
  listingType,
  amenities,
  longAdditionalFilter,
  shortAdditionalFilter,
  locationTypes
}) {
  const locationUtilsClient = getClient(SEARCH_PROP);
  // const bookingClient = getClient(BOOKING_QUERY);  // TO BE DISCUSSED LATER
  const [amenitiesOpen, setAmenitiesOpen] = useState(false);
  // const [shortAmenitiesOpen, setShortAmenitiesOpen] = useState(false);  // TBD LATER
  const [propTypesCheck, setPropTypesCheck] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [propTypesOpen, setPropTypesOpen] = useState(false);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [selectedPropTypes, setSelectedPropTypes] = useState([]);
  const [amenityTypes, setAmenityTypes] = React.useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);

  useEffect(() => {
    if (listingType === "3" || listingType === "1") {
      fetchPropertyTypes();
      longTermAmenitiesQuery();
    }
  }, [listingType]);

  useEffect(() => {
    if (amenities && amenityTypes.length > 0 && amenities.length > 0) {
      for(let i=0; i<amenityTypes.length; i++) {
        for (let j=0; j<amenities.length; j++) {
          if (amenityTypes[i].node.name === amenities[j]) {
            amenityTypes[i].node.checked = true;
          }
        }
      }
    }
  })

  useEffect(() => {
    if (locationTypes && propertyTypes.length > 0 && locationTypes.length > 0) {
      for(let i=0; i<propertyTypes.length; i++) {
        for (let j=0; j<locationTypes.length; j++) {
          if (propertyTypes[i].node.id === locationTypes[j]) {
            propertyTypes[i].node.checked = true;
          }
        }
      }
    }
  })

  const closePropTypeDropdown = (x) => {
    setPropTypesOpen(x);
  };

  const closeAmenitiesDropdown = (x) => {
    setAmenitiesOpen(x);
  };

  // wrapper to detect click outside of div
  const propTypeWrapperRef = useRef(null);
  const amenitiesWrapperRef = useRef(null);
  useOutsideClickDetection(propTypeWrapperRef, closePropTypeDropdown, false);
  useOutsideClickDetection(amenitiesWrapperRef, closeAmenitiesDropdown, false);

  // handles propertyType selection
  const handleSelection = (item, str, i) => {
    // property types
    if (str === "propTypes") {
      // handles checkbox ui
      setPropTypesCheck(
        propTypesCheck.map((item, iterator) => {
          return iterator === i
            ? !propTypesCheck[i]
            : item !== false
            ? true
            : false;
        })
      );

      // handles id addition/removal in state array
      const selected = !selectedPropTypes.includes(item)
        ? [...selectedPropTypes, item]
        : selectedPropTypes.filter((e) => e !== item);
      setSelectedPropTypes(selected);

      onChangeField("locationTypes", selected);
    } else if (str === "long") {
      // amenities
      // handles aminity addition/removal in state array
      const selected = !selectedAmenities.includes(item)
        ? [...selectedAmenities, item]
        : selectedAmenities.filter((e) => e !== item);
      setSelectedAmenities(selected);
      onChangeField("amenities", selected);
    }
  };

  // function to fetch the location types from 'getLocationType' query
  const fetchPropertyTypes = () => {
    locationUtilsClient
      .query({ query: getLocationType })
      .then((response) => {
        if (response.data.locationType) {
          setPropertyTypes(response.data.locationType.edges || []);
        }
      })
      .catch((error) => console.log(error));
  };

  // function to fetch the long term amenities from 'getAmenities' query
  const longTermAmenitiesQuery = () => {
    locationUtilsClient
      .query({ query: getAmenities })
      .then(async (response) => {
        if (response.data.amenities)
          await setAmenityTypes(() => response.data.amenities.edges || []);
      })
      .catch((error) => console.log(error));
  };

  const checkedAmenity = (a) => {
    if (a.node.checked) {
      return true;
    }
    return false;
  }

  const propertyTypePlaceholder = () => {
    if (locationTypes && locationTypes.length) {
      const data = propertyTypes.filter(x => locationTypes.includes(x.node.id));
      return `Property Type ${data.length > 0 ? `(${data.length})` : ''}`
    }
    return 'Property Type'
  }

  const amenitiesPlaceholder = () => {
    if (amenities && amenities.length) {
      let data = amenityTypes.filter(x => amenities.includes(x.node.name))
      return `Amenities ${data.length > 0 ? `(${data.length})` : ''}`
    }
    return 'Amenities';
  }

  // function to display long term filters
  const longTermFilter = () => (
    <>
      <div ref={propTypeWrapperRef}>
        <div className="search-with-icon">
          <img
            src="assets\img\search-icons\icon-property.svg"
            className="input-icon rental"
          />
          <Form.Dropdown
            className="link item services-type"
            fluid
            placeholder={propertyTypePlaceholder()}
            selection
            text={propertyTypePlaceholder()}
            onClick={() => setPropTypesOpen(true)}
            open={propTypesOpen}
          >
            <Dropdown.Menu className="inner-dropdown">
              {propertyTypes.map((k, i) => {
                return (
                  <Form.Checkbox
                    className="check-item"
                    checked={k.node.checked}
                    label={k.node.type}
                    name={k.node.type}
                    onClick={() => handleSelection(k.node.id, "propTypes", i)}
                    type="checkbox"
                    value={k.node.id}
                  />
                );
              })}
            </Dropdown.Menu>
          </Form.Dropdown>
        </div>
      </div>
      {longAdditionalFilter()}
      <div ref={amenitiesWrapperRef}>
        <div className="search-with-icon">
          <img
            src="assets\img\search-icons\icon-amenities.svg"
            className="input-icon rental"
          />
          <Form.Dropdown
            className="link item services-type"
            fluid
            onClick={() => setAmenitiesOpen(true)}
            open={amenitiesOpen}
            placeholder={amenitiesPlaceholder}
            selection
            text={amenitiesPlaceholder}
          >
            <Dropdown.Menu className="amenities-dropdown">
              {amenityTypes.map((a) => {
                return (
                  <div className="amenities-item">
                    <Checkbox
                      className="amenities-checkbox"
                      onChange={() => handleSelection(a.node.name, "long")}
                      label={a.node.name}
                      checked={checkedAmenity(a)}
                    />
                  </div>
                );
              })}
            </Dropdown.Menu>
          </Form.Dropdown>
        </div>
      </div>
    </>
  );

  //function to display the booking.com filters
  const shortTermFilter = () => (
    <>
      {shortAdditionalFilter()}
    </>
  );

  // function to show both short and long term filters in case of all option selected
  const allFilters = () => (
    <>
      <div className="search-more-row w-100">
        <div className="search-more-label">Short Term Filters</div>
        {shortAdditionalFilter()}
      </div>
      <div className="search-more-row w-100">
        <div className="search-more-label">Long Term Filters</div>
        <div className="d-flex mb-3">{longTermFilter()}</div>
      </div>
    </>
  );

  return (
    <>
      {listingType === "3" && longTermFilter()}
      {listingType === "2" && shortTermFilter()}
      {listingType === "1" && allFilters()}
      {/* Please do not remove */}

      {/* <div className="renter-search-listing-types">
                <Form.Field>Type of listings</Form.Field>
                <Form.Field>
                    <Radio
                        checked={furnished}
                        label='Furnished'
                        name='radioGroup'
                        onClick={() => setFurnished(true)}
                        onChange={(e)=> onChangeField('furnished', true)}
                        value={furnished}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        checked={!furnished}
                        label='Unfurnished'
                        name='radioGroup'
                        onClick={() => setFurnished(false)}
                        onChange={(e)=> onChangeField('furnished', false)}
                        value={furnished}
                    />
                </Form.Field>
            </div> */}
    </>
  );
}

export default AdditionalFilters;
