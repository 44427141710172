// @flow
import { Query } from "react-apollo";
import gql from "graphql-tag";

// import type { User as UserResult } from './__generated__/User'

export const personGql = gql`
  query Person {
    person {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ndbId
          firstName
          lastName
          dateOfBirth
          email
          phoneHome
          phoneMobile
          verificationType
          verificationNumber
          pointsBalance
          photoUrl
          addresses {
            street
            postcode
            city
            state
          }
          identification {
            number
            country
            stateIssued
            # identType
            # identCountry
          }
          residence {
            price
            relocationReason
            startDate
            endDate
            manager {
              name
              phone
            }
            address {
              street
              city
              state
              postcode
            }
          }
          employment {
            title
            supervisorName
            supervisorEmail
            supervisorPhone
            salary
            startDate
            endDate
            employerName
            address {
              street
              city
              state
              postcode
            }
          }
          criminalEviction {
            isEvicted
            isConvictedOfMisdemeanor
            isConvictedOfFelony
            # files {
            #   id
            #   url
            # }
          }
          reference {
            name
            title
            phone
            email
          }
        }
      }
    }
  }
`;

export class UserQuery extends Query {}
// export class UserQuery extends Query<UserResult, {}> {}
