import React, { useState } from "react";
import { withApollo } from "react-apollo";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../utils";
import "./Footer.scss";

const renderULList = (type) => {
  let returnedEle = "";
  switch (type) {
    case "Product":
      returnedEle = (
        <>
          <li>
            {/* <a href="renter.html" title="Renter">Renter</a> */}
            <NavLink exact to={ROUTES.RENTER} activeClassName="active">
              Renter
            </NavLink>
          </li>
          <li>
            {/* <a href="property-manager.html" title="Property Manager">Property Manager</a> */}
            <NavLink exact to={ROUTES.MANAGER} activeClassName="active">
              Property Manager
            </NavLink>
          </li>
          <li>
            {/* <a href="service-provider.html" title="Service Provider">Service Provider</a> */}
            <NavLink exact to={ROUTES.SERVICE} activeClassName="active">
              Service Provider
            </NavLink>
          </li>
        </>
      );
      break;
    case "Company":
      returnedEle = (
        <>
          <li>
            {/* <a href="about.html" title="About Leasera">About Leasera</a> */}
            <NavLink exact to={ROUTES.ABOUT_US} activeClassName="active">
              About REM
            </NavLink>
          </li>
          <li>
            {/* <a href="platform-product.html" title="Platform Products">Platform Products</a> */}
            <NavLink
              exact
              to={ROUTES.PLATFORM_PRODUCTS}
              activeClassName="active"
            >
              Platform Products
            </NavLink>
          </li>
          <li>
            {/* <a href="contact.html" title="Contact Us">Contact Us</a> */}
            <NavLink exact to={ROUTES.CONTACT_US} activeClassName="active">
              Contact Us
            </NavLink>
          </li>
          <li>
            {/* <a href="blog.html" title="Blog">Blog</a> */}
            <NavLink exact to={ROUTES.BLOG} activeClassName="active">
              Blog
            </NavLink>
          </li>
        </>
      );
      break;
    case "Services":
      returnedEle = (
        <>
          <li>
            {/* <a href="pet-services.html" title="Pet Services">Pet Services</a> */}
            <NavLink exact to={ROUTES.PET_SERVICES} activeClassName="active">
              Pet Services
            </NavLink>
          </li>
          <li>
            {/* <a href="grocery-shopping.html" title="Grocery Shopping">Grocery Shopping</a> */}
            <NavLink
              exact
              to={ROUTES.GROCERY_SHOPPING}
              activeClassName="active"
            >
              Grocery Shopping
            </NavLink>
          </li>
          <li>
            {/* <a href="maintenance-professional.html"
                            title="Maintenance Professionals">Maintenance Professionals</a> */}
            <NavLink
              exact
              to={ROUTES.MARKETING_PROFESSIONAL}
              activeClassName="active"
            >
              Maintenance Professionals
            </NavLink>
          </li>
          <li>
            {/* <a href="local-site.html" title="Local Sites">Local Sites</a> */}
            <NavLink exact to={ROUTES.LOCAL_SITES} activeClassName="active">
              Local Sites
            </NavLink>
          </li>
        </>
      );
      break;
    case "Support":
      returnedEle = (
        <>
          <li>
            {/* <a href="faq.html" title="FAQ’s">FAQ’s</a> */}
            <NavLink exact to="/FAQ" activeClassName="active">
              FAQ's
            </NavLink>
          </li>
          <li>
            {/* <a href="terms.html" title="Terms & Conditions">Terms & Conditions</a> */}
            <NavLink exact to="/Terms" activeClassName="active">
              Terms & Conditions
            </NavLink>
          </li>
          <li>
            {/* <a href="privacy.html" title="Privacy Policy">Privacy Policy</a> */}
            <NavLink exact to="/Privacy" activeClassName="active">
              Privacy Policy
            </NavLink>
          </li>
        </>
      );
      break;
    default:
      break;
  }
  return returnedEle;
};

const FooterMain = () => {
  const [toggleItem1, setToggleItem1] = useState(false);
  const [toggleItem2, setToggleItem2] = useState(false);
  const [toggleItem3, setToggleItem3] = useState(false);
  const [toggleItem4, setToggleItem4] = useState(false);

  const footerLinkClass = (index) => {
    console.log(document.getElementById("footerLink").className);
    switch (index) {
      case 0:
        setToggleItem1(!toggleItem1);
        break;
      case 1:
        setToggleItem2(!toggleItem2);
        break;
      case 2:
        setToggleItem3(!toggleItem3);
        break;
      case 3:
        setToggleItem4(!toggleItem4);
        break;
      default:
        break;
    }
  };

  const setFooterClass = (index) => {
    let classToReturn = "";
    switch (index) {
      case 0:
        classToReturn = toggleItem1 ? "show" : "hide";
        break;
      case 1:
        classToReturn = toggleItem2 ? "show" : "hide";
        break;
      case 2:
        classToReturn = toggleItem3 ? "show" : "hide";
        break;
      case 3:
        classToReturn = toggleItem4 ? "show" : "hide";
        break;
      default:
        break;
    }
    return classToReturn;
  };

  const setFooterImg = (index) => {
    let imgToReturn = "";
    switch (index) {
      case 0:
        imgToReturn = toggleItem1
          ? "assets/img/marketing/icons-minus.svg"
          : "assets/img/marketing/icons-plus.svg";
        break;
      case 1:
        imgToReturn = toggleItem2
          ? "assets/img/marketing/icons-minus.svg"
          : "assets/img/marketing/icons-plus.svg";
        break;
      case 2:
        imgToReturn = toggleItem3
          ? "assets/img/marketing/icons-minus.svg"
          : "assets/img/marketing/icons-plus.svg";
        break;
      case 3:
        imgToReturn = toggleItem4
          ? "assets/img/marketing/icons-minus.svg"
          : "assets/img/marketing/icons-plus.svg";
        break;
      default:
        break;
    }
    return imgToReturn;
  };

  const divData = ["Product", "Company", "Services", "Support"];
  return (
    <>
      <footer id="footer" class="site-footer">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-4 col-lg-2">
              <NavLink
                exact
                to={ROUTES.LANDING}
                activeClassName="active"
                className="nav-link"
              >
                <img src="assets/img/footer-logo.svg" height="55" alt="Logo" />
              </NavLink>
              <p class="text">© 2021-2022 Leasera Inc. All rights reserved.</p>
            </div>
            {divData.map((ele, index) => (
              <div class="col-12 col-md-4 col-lg-2">
                <div
                  class={`footer-links ${setFooterClass(index)}`}
                  id="footerLink"
                >
                  <div class="title" role="heading" tabindex="0">
                    {ele}
                    <span className="accordion-btn">
                      <img
                        src={setFooterImg(index)}
                        alt="accordio-icon"
                        onClick={() => footerLinkClass(index)}
                      />
                    </span>
                  </div>
                  <ul class="data-list">{renderULList(ele)}</ul>
                </div>
              </div>
            ))}
            <div class="col-12 col-md-4 col-lg-2">
              <div class="social-media">
                <a
                  href="https://www.facebook.com/leaseragroup"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="facebook"
                >
                  <img
                    src="assets/img/marketing/icons-facebook.svg"
                    alt="facebook"
                  />
                </a>
                <a
                  href="https://twitter.com/leaserapm"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="twitter"
                >
                  <img
                    src="assets/img/marketing/icons-twitter.svg"
                    alt="twitter"
                  />
                </a>
                {/* eslint-disable */}
                <a title="instagram">
                  <img
                    src="assets/img/marketing/icons-instagram.svg"
                    alt="instagram"
                  />
                </a>
                {/* eslint-enable */}
                <a
                  href="https://www.linkedin.com/company/leasera"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="linkedin"
                >
                  <img
                    src="assets/img/marketing/icons-linkedin.svg"
                    alt="linkedin"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* <footer className="footer-main">
                    <div className="footer-inner-wrapper">
                        <img src="assets\img\leasera-logonew-nav.png" alt="img" width="210" />
                        <div className="ant-list ant-list-sm ant-list-split">
                            <div className="ant-list-header">
                               <div><b>Product</b></div> 
                            </div>
                            <div class="ant-spin-nested-loading">
                                <div class="ant-spin-container">
                                    <ul class="ant-list-items">
                                    <List.Item className="ant-list-item"><NavLink exact to={ROUTES.RENTER} activeClassName="active" className="nav-link">Renter</NavLink></List.Item>
                                    <List.Item className="ant-list-item"><NavLink exact to={ROUTES.MANAGER} activeClassName="active" className="nav-link">Manager</NavLink></List.Item>
                                    <List.Item className="ant-list-item"><NavLink exact to={ROUTES.SERVICE} activeClassName="active" className="nav-link">Service Provider</NavLink></List.Item>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="ant-list ant-list-sm ant-list-split">
                            <div class="ant-list-header">
                                <div><b>Company</b></div>
                            </div>
                            <div class="ant-spin-nested-loading">
                                <div class="ant-spin-container">
                                    <ul class="ant-list-items">
                                    <List.Item className="ant-list-item"><NavLink exact to={ROUTES.ABOUT_US} activeClassName="active" className="nav-link">About Us</NavLink></List.Item>
                                    <List.Item className="ant-list-item"><NavLink exact to={ROUTES.CONTACT_US} activeClassName="active" className="nav-link">Contact Us</NavLink></List.Item>
                                    <List.Item className="ant-list-item"><NavLink exact to={ROUTES.BLOG} activeClassName="active" className="nav-link">Blog</NavLink></List.Item>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="ant-list ant-list-sm ant-list-split">
                            <div class="ant-list-header">
                                <div><b>Support</b></div>
                            </div>
                            <div class="ant-spin-nested-loading">
                                <div class="ant-spin-container">
                                    <ul class="ant-list-items">
                                        <List.Item className="ant-list-item"><NavLink exact to="/FAQ" activeClassName="active" className="nav-link">FAQ</NavLink></List.Item>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="ant-divider ant-divider-horizontal" role="separator"></div>
                        <div className="inner-footer-row">
                            <span className="inner-footer-copyright">© 2020 Leasera Inc.</span>
                            <div className="inner-footer-link">
                                <NavLink exact to="/Privacy" activeClassName="active" className="nav-link">Privacy Policy</NavLink>
                                <NavLink exact to="/Terms" activeClassName="active" className="nav-link">Terms of Use</NavLink>
                            </div>
                        </div>
                    </div>
                </footer>

            <Segment style={{ padding: '8em 0em', display: 'none' }} vertical size="small" attached className="footer-wrapper">
                <Grid columns="4">
                    <Grid.Column>
                        <Image src='assets\img\leasera-logonew-nav.png' size='medium' className="footer-logo" />
                    </Grid.Column>
                    <Grid.Column>
                        <List link>
                            <List.Item active>Product</List.Item>
                            <List.Item as='a'><NavLink exact to={ROUTES.RENTER} activeClassName="active" className="nav-link">Renter</NavLink></List.Item>
                            <List.Item as='a'><NavLink exact to={ROUTES.MANAGER} activeClassName="active" className="nav-link">Manager</NavLink></List.Item>
                            <List.Item as='a'><NavLink exact to={ROUTES.SERVICE} activeClassName="active" className="nav-link">Service Provider</NavLink></List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column>
                        <List link>
                            <List.Item active>Company</List.Item>
                            <List.Item as='a'><NavLink exact to={ROUTES.ABOUT_US} activeClassName="active" className="nav-link">About Us</NavLink></List.Item>
                            <List.Item as='a'><NavLink exact to={ROUTES.CONTACT_US} activeClassName="active" className="nav-link">Contact Us</NavLink></List.Item>
                            <List.Item as='a'><NavLink exact to={ROUTES.BLOG} activeClassName="active" className="nav-link">Blog</NavLink></List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column>
                        <List link>
                            <List.Item active>Support</List.Item>
                            <List.Item as='a'><NavLink exact to="/FAQ" activeClassName="active" className="nav-link">FAQ</NavLink></List.Item>
                            <List.Item as='a'><NavLink exact to="/Terms" activeClassName="active" className="nav-link">Terms of Use</NavLink></List.Item>
                            <List.Item as='a'><NavLink exact to="/Privacy" activeClassName="active" className="nav-link">Privacy Policy</NavLink></List.Item>
                        </List>
                    </Grid.Column>
                </Grid>
            </Segment> */}
    </>
  );
};

export default withApollo(FooterMain);
