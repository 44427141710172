import React from "react";
import { Card, Image } from "semantic-ui-react";

const MapInfoCard = ({ property, handleRedirect }) => {
  const url = JSON.parse(property.photos);
  return (
    <Card>
      <Image src={url} wrapped ui={false} style={{ width: "250px" }} />
      <Card.Content className="property-info-header">
        <Card.Header onClick={() => handleRedirect(property, !property.hotelId ? 'long' : undefined)}>
          {property.name}
        </Card.Header>
      </Card.Content>
    </Card>
  );
};

export default MapInfoCard;
