// @flow
import Cookie from "js-cookie";
import config from "../../config";
import { getAuthToken, getClient } from "../auth";
import {
  TOKEN,
  EXPIRE_TIME,
  RELOAD,
  LOGIN,
  SESSION_TIMEOUT_THRESHOLD,
  USER_EVENT,
  USER_ROLE_EVENT,
  RENTER,
  MANAGER,
  SERVICEPROFESSIONAL,
  PERSON_FN_URL,
} from "../../utils/constants";
import { getEmitter } from "../utils";
import { personGql } from "../person";
import { auth } from "../../containers/Auth/Firebase";

let sessionTimeout = null;

export const registerAuthToken = (token) => {
  Cookie.set(TOKEN, token, {
    domain: config.domain,
  });
};

export const loginAction = ({ email, password }, client) => {
  return auth.signInWithEmailAndPassword(email, password).then(async () => {
    try {
      onRequestSuccess(client);
    } catch (e) {
      throw new Error("User authentication failed.");
    }
    return auth.currentUser;
  });
};

const setSessionTimeout = (duration, client) => {
  clearTimeout(sessionTimeout);
  sessionTimeout = setTimeout(
    refreshToken,
    (duration - SESSION_TIMEOUT_THRESHOLD) * 1000,
    { client }
  );
};

const refreshToken = ({ client }) => {
  const session = getAuthToken();
  if (!session) {
    return Promise.reject();
  }
  onRequestSuccess(client, RELOAD);
};

export const onRequestSuccess = async (client, from = LOGIN) => {
  const time = "3600";
  let authFlag = true;
  if (from === LOGIN || (from === RELOAD && getAuthToken())) {
    const eventEmit = getEmitter();
    auth.onAuthStateChanged(async function (user) {
      if (authFlag) {
        authFlag = false;
        if (user) {
          const token = await auth.currentUser.getIdToken(true);
          registerAuthToken(token);
          getUserRoles(auth);
          if (from === LOGIN) {
            const newClient = getClient(PERSON_FN_URL);
            try {
              newClient
                .query({
                  query: personGql,
                })
                .then((res) => {
                  eventEmit.emit(USER_EVENT, {
                    data: res,
                  });
                })
                .catch((e) => {
                  console.log(e);
                });
            } catch (e) {
              return;
            }
          }
          // expire time is current time  plus 55 minutes more
          Cookie.set(EXPIRE_TIME, new Date().getTime() + 55 * 60 * 1000, {
            domain: config.domain,
          });
          setSessionTimeout(time, client);
        } else {
          // No user is signed in.
        }
      }
    });
  }
};

export const getUserRoles = async (auth) => {
  auth.currentUser
    .getIdTokenResult()
    .then((idTokenResult) => {
      if (idTokenResult.claims) {
        const eventEmit = getEmitter();
        Cookie.set(RENTER, idTokenResult.claims.renter, {
          domain: config.domain,
        });
        Cookie.set(MANAGER, idTokenResult.claims.manager, {
          domain: config.domain,
        });
        Cookie.set(
          SERVICEPROFESSIONAL,
          idTokenResult.claims.serviceProfessional,
          {
            domain: config.domain,
          }
        );
        eventEmit.emit(USER_ROLE_EVENT);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export default {
  loginAction,
  onRequestSuccess,
};
