import gql from "graphql-tag";

export const processBookingGql = gql`
  mutation processbooking($booking: ProcessBookingInput!) {
    processBooking(booking: $booking) {
      response
      status
    }
  }
`;
