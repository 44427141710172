// @flow
/* eslint-disable */
import { flatten } from "flat";
import moment from "moment";

export const stringifyDates = (values) => {
  const valuesWithStringDates = {};
  Object.keys(values).map((k) => {
    if (moment.isMoment(values[k])) {
      valuesWithStringDates[k] = moment(values[k]).format("YYYY-MM-DD");
    } else {
      valuesWithStringDates[k] = values[k];
    }
  });
  return valuesWithStringDates;
};

export const updateUrl = ({ query, pathname, history }) => {
  const flatValues = flatten(query);
  const flatValuesWithStringDates = stringifyDates(flatValues);
  history.push({
    pathname: `/${pathname}`,
    state: query,
    search: Object.keys(flatValuesWithStringDates)
      .map((key) => `${key}=${flatValuesWithStringDates[key]}`)
      .join("&"),
  });
};

export default updateUrl;
