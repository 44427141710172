import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import moment from "moment";
import { MIN_YEAR } from "../../../utils/constants";

function DropdownDate(props) {
  const [year, setYear] = useState(moment(props.dateOfBirth).year());
  const [month, setMonth] = useState(moment(props.dateOfBirth).month() + 1);
  const [day, setDate] = useState(moment(props.dateOfBirth).date());
  const minYear = new Date().getFullYear() - MIN_YEAR;
  const getYearsList = () => {
    const years = [];
    const thisYear = new Date().getFullYear();
    const minOffset = 18;
    const maxOffset = 108;
    for (let i = minOffset; i <= maxOffset; i++) {
      const year = thisYear - i;
      years.unshift(year);
    }
    return years.sort((a, b) => b - a);
  };

  const getDaysList = ({ year, month }) => {
    const daysInMonth = moment().year(year).month(month).daysInMonth();
    return [...Array(daysInMonth).keys()].map((i) => i + 1); // days from 1 to x
  };

  const handleChange = (value, field) => {
    const dayValue = value;
    // if (typeof year === 'number' && typeof month === 'number' && typeof day === 'number') {
    switch (field) {
      case "year":
        setYear(value);
        break;
      case "month":
        setMonth(value);
        break;
      case "day":
        setDate(value);
        break;
      default:
        break;
    }
    const newDate = moment(
      `${field === "year" ? dayValue : year}-${
        field === "month" ? dayValue : month
      }-${field === "day" ? dayValue : day}`
    ).format("YYYY-MM-DD");
    props.setDob(newDate);
    //}
  };

  const yearOptions = getYearsList().map((year) => ({
    value: year,
    key: year,
    text: year,
  }));
  const monthOptions = moment
    .monthsShort()
    .map((month, i) => ({ value: i + 1, key: month, text: month }));
  const getDateOptions = ({ year, month }) =>
    getDaysList({ year, month }).map((day) => ({
      value: day,
      key: day,
      text: day,
    }));
  return (
    <div className="custom-date-dropdown form-group">
      <label>Date of birth</label>
      <div className="form-control">
        <Form.Select
          placeholder={minYear.toString()}
          options={yearOptions}
          value={year !== null ? year : minYear}
          onChange={(e, data) => handleChange(data.value, "year")}
        />
        <Form.Select
          placeholder="MM"
          options={monthOptions}
          value={month !== null ? month : undefined}
          onChange={(e, data) => handleChange(data.value, "month")}
        />
        <Form.Select
          placeholder="DD"
          options={getDateOptions({ year, month })}
          value={day !== null ? day : undefined}
          onChange={(e, data) => handleChange(data.value, "day")}
        />
        <div></div>
      </div>
    </div>
  );
}

export default DropdownDate;
