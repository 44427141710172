import React, { Component } from "react";
import { withApollo } from "react-apollo";
import "./Privacy.scss";
import FooterMain from "../../components/Footer/FooterMain";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div class="platform-products-wrapper privacy-wrapper">
          <Header />
          <div class="banner banner-service-provider">
            <div class="container text-center">
              <div class="row">
                <div class="col-12">
                  <h1>Privacy Policy</h1>
                </div>
              </div>
            </div>
          </div>
          <section class="privacy-wrapper">
            <div class="container">
              <div class="content-data">
                {/* <span>Last modified: July 17, 2017 </span> */}
                <p>
                  This Leasera Privacy Policy (this “<b>Policy</b>”) describes
                  the types of information Real Estate Ally LLC, a Washington
                  limited liability company (“<b>Leasera</b>” “<b>we</b>,” “
                  <b>our</b>,” or “<b>us</b>”) may collect from you, that you
                  may provide us, or that we may gather about you from
                  authorized third-parties when you use the website
                  www.Leasera.com, any other websites created and maintained by
                  Leasera (collectively, the “<b>Websites</b>”), or any services
                  provided via the Websites (the “<b>Services</b>”). This Policy
                  also describes our practices for collecting, using,
                  maintaining, protecting, and disclosing such information. This
                  Policy does not apply to information collected by us offline
                  or by any third-party that may be accessible or linked to or
                  from the Websites.
                </p>
                <p>
                  By accessing or using the Websites and/or the Services, you
                  agree to this Policy. If you do not agree with the terms of
                  this Policy, do not use the Websites, the Services, or provide
                  any information to us. This Policy may change from time to
                  time and we will post such changes on the Websites. Your
                  continued use of the Websites or the Services after any
                  revisions we make to this Policy are posted on the Websites
                  constitutes your acceptance and agreement to those changes, so
                  please check the Websites periodically for updates. In the
                  event you enter into an agreement with Leasera for the
                  provision of Services (“<b>Terms of Service</b>”), and there
                  is a conflict between the terms of this Policy and the Terms
                  of Service, the Terms of Service shall prevail.
                </p>
              </div>
              <ul>
                <li>
                  <h4>1. INFORMATION WE COLLECT</h4>
                  <p>
                    We may collect the following types of information when you
                    use the Websites or the Services:{" "}
                  </p>
                  <ul class="child-element">
                    <li>
                      <span>
                        “<b>Personal Information</b>”:{" "}
                      </span>
                      <p>
                        information that identifies you or another person as an
                        individual, such as your or someone else’s name, mailing
                        address, e-mail address, telephone number, IP address,
                        or any other identifier by which you or any other
                        individual may be contacted online or offline. For the
                        purpose of this Policy, Personal Information shall also
                        include your financial information such as debit or
                        credit card information, checking account information,
                        or other non-public financial information that you
                        provide to us or we gather from authorized
                        third-parties.{" "}
                      </p>
                    </li>
                    <li>
                      <span>
                        “<b>Non-Personal Information</b>”:{" "}
                      </span>
                      <p>
                        information that does not identify you as an individual,
                        such as your browser type, your internet connection, the
                        URL of the previous web site you visited, the equipment
                        you use to access the Websites, and details about how
                        you use the Website or the Services.{" "}
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>2. HOW WE COLLECT YOUR INFORMATION </h4>
                  <p>
                    We may collect information (i) when you provide it to us;
                    (ii) automatically through the Websites or the Services; or
                    (iii) through authorized third-parties.{" "}
                  </p>

                  <ul class="child-element">
                    <li>
                      <span>Information You Provide to Us.</span>
                      <p>
                        When you use the Websites or the Services, you may
                        choose to provide us with both Personal and Non-Personal
                        Information, including when you:
                      </p>
                      <ul class="subchild-element">
                        <li>
                          Complete and submit forms on the Websites or through
                          the Services, including registering with Leasera using
                          social media credentials like Facebook.{" "}
                        </li>
                        <li>Use the Services.</li>
                        <li>Contact us.</li>
                        <li>
                          Report a problem with the Website or the Services.{" "}
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span>Information We Collect Automatically.</span>
                      <p>
                        We do not collect Personal Information automatically,
                        with the exception of IP addresses which may be
                        collected automatically and are only used as described
                        below. [Other than IP addresses,] we do not collect
                        real-time information about you or your devices via the
                        Websites or the Services. When you access and use the
                        Websites or the Services, we may use automatic data
                        collection technologies to automatically collect:{" "}
                      </p>
                      <ul class="subchild-element">
                        <li>
                          Usage details (ex. details regarding your access to
                          and use of the Websites and the Services); and
                        </li>
                        <li>
                          Device information (ex. information about your
                          computer or mobile device and/or internet connection,
                          including the device's unique device identifier,
                          operating system, browser type, and/or mobile network
                          information).{" "}
                        </li>
                      </ul>
                    </li>
                    <p>
                      If you do not want us to collect this information then
                      please do not use the Websites or the Services.{" "}
                    </p>
                    <p>
                      The technologies we use for automatic information
                      collection may include “cookies.” A cookie is a small file
                      placed on your browsing device. It may be possible to
                      refuse to accept cookies by activating the appropriate
                      setting on your computer, tablet, or smartphone. However,
                      if you select this setting you may be unable to access
                      certain parts of our Websites.{" "}
                    </p>
                    <li>
                      <span>
                        Information We Collect from Authorized Third-Parties.
                      </span>
                      <p>
                        From time-to-time you may authorize us to collect
                        certain Personal Information about you. Such Personal
                        Information may include, but not be limited to,
                        information Leasera obtains by conducting a background
                        check or conducting other screening activities in
                        connection with your use of the Services (“
                        <b>Pre-Screening</b>”).
                      </p>
                    </li>
                  </ul>
                </li>

                <li>
                  <h4>3. THIRD PARTY SERVICE PROVIDERS </h4>
                  <p>
                    Some content, including advertisements, on the Websites or
                    Services may be served by third-parties, including
                    advertisers, ad networks and servers, content providers, and
                    application providers. These third parties may use cookies
                    or other tracking technologies to collect information about
                    you when you use the websites and the services. We do not
                    control these third parties' tracking technologies or how
                    they may be used. If you have any questions about an
                    advertisement or other targeted content, you should contact
                    the responsible provider directly.{" "}
                  </p>
                </li>
                <li>
                  <h4>4. HOW WE USE YOUR INFORMATION </h4>
                  <p>
                    We may use the information provided by or collected from
                    you, or information that we receive from authorized third
                    parties, including Personal Information, in order to:{" "}
                  </p>
                  <ul class="subchild-element">
                    <li>
                      Make the Websites and the Services available to you;{" "}
                    </li>
                    <li>
                      To confirm your identity and eligibility for certain
                      products and Services;{" "}
                    </li>
                    <li>
                      Provide you with information, products, or Services that
                      you request from us;
                    </li>
                    <li>Conduct Pre-Screening; </li>
                    <li>
                      Fulfill any other purpose for which you provide or we
                      receive the information; and
                    </li>
                    <li>
                      Improve the Websites, our products, and the Services.{" "}
                    </li>
                  </ul>
                  <p>
                    We will not hold information provided by you for
                    Pre-Screening purposes beyond 30 days from the date we
                    receive it, or as otherwise required by law. We may also use
                    the information we collect in any other way we describe at
                    the time you provide the information to us, and for any
                    other purpose with your consent.{" "}
                  </p>
                </li>
                <li>
                  <h4>5. DISCLOSURE OF YOUR INFORMATION </h4>
                  <p>
                    We do not share your Personal Information with third-parties
                    in any manner not described in this Policy.{" "}
                  </p>
                  <p>
                    We may disclose aggregated Non-Personal Information about
                    our users, and information that does not identify any
                    individual, to third-parties to help us improve the Websites
                    and the Services or for any other commercially valid
                    purposes.{" "}
                  </p>
                  <p>We may disclose your Personal Information:</p>
                  <ul class="subchild-element">
                    <li>
                      To contractors, service providers, and other third-parties
                      we use to support our business (“<b>Service Providers</b>
                      ”). In such circumstances, the Service Providers may be
                      bound by contractual obligations to keep your Personal
                      Information confidential and use it only for the purposes
                      for which we disclose it to them; however, not all such
                      Service Providers will be bound by such contractual
                      obligations. In such an event Your Personal Information
                      will be governed by the Service Providers’ terms of use
                      and privacy policies.
                    </li>
                    <li>
                      To a buyer or successor in the event of a merger,
                      reorganization, dissolution, or other sale or transfer of
                      all or substantially all of Leasera’s assets or equity
                      interests.
                    </li>
                    <li>
                      To fulfill the purpose for which you provide it or we
                      receive it with authorization. For example, if you give us
                      an email address or you register with us using your social
                      media credentials (e.g., Facebook), in order for us to
                      provide you more information about the Websites or our
                      Services, we will use that email address or that social
                      media credential to provide the requested information to
                      you.{" "}
                    </li>
                    <li>
                      For any other purpose disclosed by us when you provide
                      Personal Information.{" "}
                    </li>
                    <li>With your consent. </li>
                  </ul>
                  <p>We may also disclose your Personal Information: </p>
                  <ul class="subchild-element">
                    <li>
                      To comply with any court order, law, or legal process,
                      including to respond to any government or regulatory
                      request.{" "}
                    </li>
                    <li>
                      If we believe disclosure is necessary or appropriate to
                      protect the rights, property, or safety of Leasera or its
                      affiliates, customers, or others.{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>6. YOUR CHOICES AND OPTING OUT </h4>
                  <p>
                    We may periodically send you email messages describing news,
                    information, updates, or promotional offers related to the
                    Websites, our products, or our Services.{" "}
                  </p>
                  <p>
                    You can “opt-out” of receiving emails from us at any time by
                    following the instructions included in your email and we
                    will make the requested changes. Whether you opt out of
                    receiving promotional emails or not, Leasera reserves the
                    right to email or otherwise contact you with respect to
                    important information, important notices, or important
                    changes relating to our products and Services.{" "}
                  </p>
                </li>
                <li>
                  <h4>7. Accessing and Correcting Personal Information </h4>
                  <p>
                    You can review and change your Personal Information by
                    logging into your account with us; or, if such Personal
                    Information is not available by logging into your account
                    with us, please send us an email at info@Leasera.com to
                    request access to, or to correct or delete any of your
                    Personal Information in our possession. We may not change or
                    delete information if we believe the change or deletion
                    would violate any law or legal requirement or cause the
                    information to be incorrect.{" "}
                  </p>
                </li>
                <li>
                  <h4>8. DATA SECURITY </h4>
                  <p>
                    The transmission of information via the Internet, and the
                    storage of such information, is not completely secure.
                    Although we have commercially reasonable security measures
                    in place to protect your Personal Information, we cannot
                    guarantee the security of your Personal Information. You are
                    providing, or allow us to gather, your Personal Information
                    to us at your own risk. We are not responsible for failure
                    or circumvention of any privacy settings or security
                    measures we may have in place to protect your Personal
                    Information.
                  </p>
                </li>
                <li>
                  <h4>9. Children Under the Age of 13 </h4>
                  <p>
                    The Websites and the Services are not intended for use by
                    children. We do not knowingly collect information from
                    children under the age of 13. If you are under 13, do not
                    use the Websites, the Services, or provide any information
                    to Leasera through the Websites. If we learn that Personal
                    Information of a child under the age of 13 is collected, we
                    will delete that information from our systems. If you
                    believe we might have any information from or about a child
                    under 13, please contact us at info@Leasera.com.{" "}
                  </p>
                </li>
                <li>
                  <h4>10. Changes to Our Privacy Policy </h4>
                  <p>
                    We may modify or amend this Policy from time to time. The
                    date this Policy was last revised is identified at the top
                    of the page and the terms of this Policy are effective as of
                    that date.{" "}
                  </p>
                </li>
                <li>
                  <h4>11. Contact Information </h4>
                  <p>
                    To ask questions or comment about this Policy and our
                    privacy practices, contact us at: info@Leasera.com{" "}
                  </p>
                </li>
              </ul>
            </div>
          </section>
          <Newsletter />
          <FooterMain />
        </div>
        {/* <div className="top-privacy-background">
                    <h2 className="top-privacy-header">Privacy Policy</h2>
                </div>

                <div className="privacy-data-container">
                    <p>This RE Ally Privacy Policy (this “Policy”) describes the types of information Real Estate Ally LLC, a Washington limited liability company (“RE Ally” “we,” “our,” or “us”) may collect from you, that you may provide us, or that we may gather about you from authorized third-parties when you use the website www.re-ally.com, any other websites created and maintained by RE Ally (collectively, the&nbsp;“Websites”), or any services provided via the Websites (the “Services”).  This Policy also describes our practices for collecting, using, maintaining, protecting, and disclosing such information.  This Policy does not apply to information collected by us offline or by any third-party that may be accessible or linked to or from the Websites.</p>
                    <p>By accessing or using the Websites and/or the Services, you agree to this Policy.  If you do not agree with the terms of this Policy, do not use the Websites, the Services, or provide any information to us.  This Policy may change from time to time and we will post such changes on the Websites.  Your continued use of the Websites or the Services after any revisions we make to this Policy are posted on the Websites constitutes your acceptance and agreement to those changes, so please check the Websites periodically for updates.  In the event you enter into an agreement with RE Ally for the provision of Services (“Terms of Service”), and there is a conflict between the terms of this Policy and the Terms of Service, the Terms of Service shall prevail.</p>
                    <ul>
                        <li>
                            <h3 style={{textDecoration:'underline', fontWeight:'500'}}>INFORMATION WE COLLECT.</h3>
                            <p>We may collect the following types of information when you use the Websites or the Services:</p>
                            <ul>
                                <li>
                                    <p><h4>“Personal Information”:</h4> information that identifies you or another person as an individual, such as your or someone else’s name, mailing address, e-mail address, telephone number, IP address, or any other identifier by which you or any other individual may be contacted online or offline.   For the purpose of this Policy, Personal Information shall also include your financial information such as debit or credit card information, checking account information, or other non-public financial information that you provide to us or we gather from authorized third-parties.</p>
                                </li>
                                <li>
                                    <p><h4>“Non-Personal Information”:</h4> nformation that does not identify you as an individual, such as your browser type, your internet connection, the URL of the previous web site you visited, the equipment you use to access the Websites, and details about how you use the Website or the Services.</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <h3 style={{textDecoration:'underline', fontWeight:'500'}}>How We Collect Your Information. </h3>
                            <p>We may collect information (i) when you provide it to us; (ii) automatically through the Websites or the Services; or (iii) through authorized third-parties.</p>
                            <p><h4 style={{textDecoration: 'underline'}}>Information You Provide to Us.</h4>  When you use the Websites or the Services, you may choose to provide us with both Personal and Non-Personal Information, including when you:</p>
                            <ul>
                                <li><p>Complete and submit forms on the Websites or through the Services, including registering with RE Ally using social media credentials like Facebook.</p></li>
                                <li><p>Use the Services.</p></li>
                                <li><p>Contact us.</p></li>
                                <li><p>Report a problem with the Website or the Services.</p></li>
                            </ul>
                            <p><h4 style={{textDecoration: 'underline'}}>Information We Collect Automatically.</h4>  We do not collect Personal Information automatically, with the exception of IP addresses which may be collected automatically and are only used as described below.  [Other than IP addresses,] we do not collect real-time information about you or your devices via the Websites or the Services.  When you access and use the Websites or the Services, we may use automatic data collection technologies to automatically collect:</p>
                            <ul>
                                <li><p>Usage details (ex.  details regarding your access to and use of the Websites and the Services); and</p></li>
                                <li><p>Device information (ex.  information about your computer or mobile device and/or internet connection, including the device's unique device identifier, operating system, browser type, and/or mobile network information).</p></li>
                            </ul>
                            <p>If you do not want us to collect this information then please do not use the Websites or the Services.</p>
                            <p>The technologies we use for automatic information collection may include “cookies.”  A cookie is a small file placed on your browsing device.  It may be possible to refuse to accept cookies by activating the appropriate setting on your computer, tablet, or smartphone.  However, if you select this setting you may be unable to access certain parts of our Websites.</p>
                            <p><h4 style={{textDecoration: 'underline'}}>Information We Collect from Authorized Third-Parties.</h4>  From time-to-time you may authorize us to collect certain Personal Information about you.  Such Personal Information may include, but not be limited to, information RE Ally obtains by conducting a background check or conducting other screening activities in connection with your use of the Services (“Pre-Screening”).</p>
                        </li>
                        <li>
                            <h3 style={{textDecoration:'underline', fontWeight:'500'}}>Third Party Service Providers </h3>
                            <p>Some content, including advertisements, on the Websites or Services may be served by third-parties, including advertisers, ad networks and servers, content providers, and application providers.  These third parties may use cookies or other tracking technologies to collect information about you when you use the websites and the services.  We do not control these third parties' tracking technologies or how they may be used.  If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.</p>
                        </li>
                        <li>
                            <h3 style={{textDecoration:'underline', fontWeight:'500'}}>How We Use Your Information </h3>
                            <p>We may use the information provided by or collected from you, or information that we receive from authorized third parties, including Personal Information, in order to:</p>
                            <ul>
                                <li><p>Make the Websites and the Services available to you;</p></li>
                                <li><p>To confirm your identity and eligibility for certain products and Services;</p></li>
                                <li><p>Provide you with information, products, or Services that you request from us;</p></li>
                                <li><p>Conduct Pre-Screening;</p></li>
                                <li><p>Fulfill any other purpose for which you provide or we receive the information; and</p></li>
                                <li><p>Improve the Websites, our products, and the Services.</p></li>
                            </ul>
                            <p>We will not hold information provided by you for Pre-Screening purposes beyond 30 days from the date we receive it, or as otherwise required by law.  We may also use  the information we collect in any other way we describe at the time you provide the information to us, and for any other purpose with your consent.</p>
                        </li>
                        <li>
                            <h3 style={{textDecoration:'underline', fontWeight:'500'}}>Disclosure of Your Information </h3>
                            <p>We do not share your Personal Information with third-parties in any manner not described in this Policy.</p>
                            <p>We may disclose aggregated Non-Personal Information about our users, and information that does not identify any individual, to third-parties to help us improve the Websites and the Services or for any other commercially valid purposes.</p>
                            <p>We may disclose your Personal Information:</p>
                            <ul>
                                <li><p>To contractors, service providers, and other third-parties we use to support our business (“Service Providers”). In such circumstances, the Service Providers may be bound by contractual obligations to keep your Personal Information confidential and use it only for the purposes for which we disclose it to them; however, not all such Service Providers will be bound by such contractual obligations.  In such an event Your Personal Information will be governed by the Service Providers’ terms of use and privacy policies.</p></li>
                                <li><p>To a buyer or successor in the event of a merger, reorganization, dissolution, or other sale or transfer of all or substantially all of RE Ally’s assets or equity interests.</p></li>
                                <li><p>To fulfill the purpose for which you provide it or we receive it with authorization.  For example, if you give us an email address or you register with us using your social media credentials (e.g., Facebook), in order for us to provide you more information about the Websites or our Services, we will use that email address or that social media credential to provide the requested information to you.</p></li>
                                <li><p>For any other purpose disclosed by us when you provide Personal Information.</p></li>
                                <li><p>With your consent.</p></li>
                            </ul>
                            <p>We may also disclose your Personal Information:</p>
                            <ul>
                                <li><p>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</p></li>
                                <li><p>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of RE Ally or its affiliates, customers, or others.</p></li>
                            </ul>
                        </li>
                        <li>
                            <h3 style={{textDecoration:'underline', fontWeight:'500'}}>Your Choices and Opting Out</h3>
                            <p>We may periodically send you email messages describing news, information, updates, or promotional offers related to the Websites, our products, or our Services.</p>
                            <p>You can “opt-out” of receiving emails from us at any time by following the instructions included in your email and we will make the requested changes.  Whether you opt out of receiving promotional emails or not, RE Ally reserves the right to email or otherwise contact you with respect to important information, important notices, or important changes relating to our products and Services.</p>
                        </li>
                        <li>
                            <h3 style={{textDecoration:'underline', fontWeight:'500'}}>Accessing and Correcting Personal Information</h3>
                            <p>You can review and change your Personal Information by logging into your account with us; or, if such Personal Information is not available by logging into your account with us, please send us an email at info@re-ally.com to request access to, or to correct or delete any of your Personal Information in our possession.  We may not change or delete information if we believe the change or deletion would violate any law or legal requirement or cause the information to be incorrect.</p>
                        </li>
                        <li>
                            <h3 style={{textDecoration:'underline', fontWeight:'500'}}>Data Security</h3>
                            <p>The transmission of information via the Internet, and the storage of such information, is not completely secure.  Although we have commercially reasonable security measures in place to protect your Personal Information, we cannot guarantee the security of your Personal Information.  You are providing, or allow us to gather, your Personal Information to us at your own risk.  We are not responsible for failure or circumvention of any privacy settings or security measures we may have in place to protect your Personal Information.</p>
                        </li>
                        <li>
                            <h3 style={{textDecoration:'underline', fontWeight:'500'}}>Children Under the Age of 13</h3>
                            <p>The Websites and the Services are not intended for use by children.  We do not knowingly collect information from children under the age of 13.  If you are under 13, do not use the Websites, the Services, or provide any information to RE Ally through the Websites.  If we learn that Personal Information of a child under the age of 13 is collected, we will delete that information from our systems.  If you believe we might have any information from or about a child under 13, please contact us at info@re-ally.com.</p>
                        </li>
                        <li>
                            <h3 style={{textDecoration:'underline', fontWeight:'500'}}>Changes to Our Privacy Policy</h3>
                            <p>We may modify or amend this Policy from time to time.  The date this Policy was last revised is identified at the top of the page and the terms of this Policy are effective as of that date.</p>
                        </li>
                        <li>
                            <h3 style={{textDecoration:'underline', fontWeight:'500'}}>Contact Information</h3>
                            <p>To ask questions or comment about this Policy and our privacy practices, contact us at: info@re-ally.com</p>
                        </li>
                    </ul>
                </div> */}
      </>
    );
  }
}

export default withApollo(Privacy);
