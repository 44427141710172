import React from "react";

const Testimonial = () => {
  return (
    <>
      {/* <section class="happyRenter-section">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-4 happyRenter-col">
                        <span class="happyRenter-icon">"</span>
                        <a class="badge-btn">Happy Renters</a>
                        <h4>Hear it straight from other renters</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sapien nisi, scelerisque
                            eget nisl non, ultricies consectetur sapien.</p>
                    </div>
                    <div class="col-12 col-lg-8 happyRenter-carousel">
                        <img src="assets/img/marketing/square_circles.png" alt="Dots" class="fixed-square-image" />
                        <Slider {...settings}>
                            <div class="card">
                                <div className="card-wrap">
                                    <div class="card-header">
                                        <div class="card-image">
                                            <img src="assets/img/marketing/sp_image_3.jpg" alt="home_image_1" />
                                        </div>
                                        <div class="card-title">
                                            <p class="card-name">Jackson Richardson</p>
                                            <p class="card-locate">Seattle, WA</p>
                                        </div>
                                    </div>
                                    <div class="card-content">
                                        <h5>Awesome services </h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                                            sapien nisi, scelerisque eget nisl non, ultricies consectetur
                                            sapien. Sed lacus est.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div className="card-wrap">
                                    <div class="card-header">
                                        <div class="card-image">
                                            <img src="assets/img/marketing/sp_image_3.jpg" alt="home_image_1" />
                                        </div>
                                        <div class="card-title">
                                            <p class="card-name">Jackson Richardson</p>
                                            <p class="card-locate">Seattle, WA</p>
                                        </div>
                                    </div>
                                    <div class="card-content">
                                        <h5>Awesome services </h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                                            sapien nisi, scelerisque eget nisl non, ultricies consectetur
                                            sapien. Sed lacus est.</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="card">
                                <div className="card-wrap">
                                    <div class="card-header">
                                        <div class="card-image">
                                            <img src="assets/img/marketing/sp_image_3.jpg" alt="home_image_1" />
                                        </div>
                                        <div class="card-title">
                                            <p class="card-name">Jackson Richardson</p>
                                            <p class="card-locate">Seattle, WA</p>
                                        </div>
                                    </div>
                                    <div class="card-content">
                                        <h5>Awesome services </h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                                            sapien nisi, scelerisque eget nisl non, ultricies consectetur
                                            sapien. Sed lacus est.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div className="card-wrap">
                                    <div class="card-header">
                                        <div class="card-image">
                                            <img src="assets/img/marketing/sp_image_3.jpg" alt="home_image_1" />
                                        </div>
                                        <div class="card-title">
                                            <p class="card-name">Jackson Richardson</p>
                                            <p class="card-locate">Seattle, WA</p>
                                        </div>
                                    </div>
                                    <div class="card-content">
                                        <h5>Awesome services </h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                                            sapien nisi, scelerisque eget nisl non, ultricies consectetur
                                            sapien. Sed lacus est.</p>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </section> */}
    </>
  );
};

export default Testimonial;
