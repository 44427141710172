// @flow
const isProd = process.env.REACT_APP_STAGING === "production";
const isStaging = process.env.REACT_APP_STAGING === "dev";

// const BFF_URL ='https://us-central1-leasera-staging.cloudfunctions.net'
const BFF_URL = 'https://service-location-search-cg2k2cekgq-uc.a.run.app'

module.exports = {
  env: process.env.REACT_APP_STAGING,
  isProd,
  isStaging,
  PORT: process.env.PORT || (isProd ? 8080 : 3000),
  BFF_URL,
  CUSTOM_ENV: process.env.CUSTOM_ENV,
  domain: 'leasera.com' ,
  searchWebsiteUri: 'https://staging.leasera.com' ,
  renterPortalUri: 'https://staging.renter.leasera.com' ,
  managerPortalUri: 'https://staging.manager.leasera.com',
  GRAPHQL_URL: BFF_URL + '/location_search/graphql',
  SENTRY_URL: 'https://8375c4f116af410382124b89d8ffcbf5@sentry.io/1318669',
}
