function stringToColor(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
}

export const Logger = (title) => {
  let color = stringToColor(title.toLowerCase());
  const isProd = process.env.NODE_ENV === "production";

  return {
    error: (subtitle, data, ...data2) => {
      if (isProd) return;
      console.log(
        `%c[ERROR] ${title} - ${subtitle}`,
        `color: ${color}`,
        data,
        data2
      );
    },
    log: (subtitle, data, ...data2) => {
      if (isProd) return;
      console.log(
        `%c[LOG] ${title} - ${subtitle}`,
        `color: ${color}`,
        data,
        data2
      );
    },
  };
};
