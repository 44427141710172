import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import get from "lodash/get";
import config from "../../config";

function UserMenu({ person, isHidden, handleModalToggle, userRoles }) {
  const user = get(person, "person.edges[0].node", null);
  const [userName, setUserName] = useState("");
  /* eslint-disable */
  useEffect(() => {
    if (user) {
      setUserName(
        `${user?.firstName ? user?.firstName : ""} ${
          user?.lastName ? user?.lastName.charAt(0) : ""
        }.`
      );
    }
  }, [user]);
  /* eslint-enable */

  const trigger = (
    <>
      {/* <Image src='https://react.semantic-ui.com/images/wireframe/square-image.png'
                verticalAlign='middle' className="logo" size='mini' circular />{' '} */}
      <span className="dropdown-header">{userName}</span>
    </>
  );
  return (
    <>
      {userRoles ? (
        <Dropdown
          className={`account-logo ${isHidden ? "primary" : ""}`}
          trigger={trigger}
        >
          <Dropdown.Menu>
            {userRoles.includes("renter") && (
              <>
                <Dropdown.Item
                  className="account-txt"
                  onClick={() =>
                    window.open(config.renterPortalUri + "/#/profile", "_blank")
                  }
                  text="My Account"
                  key="user"
                />
                <Dropdown.Item
                  className="renter-txt"
                  onClick={() => window.open(config.renterPortalUri, "_blank")}
                  text="Renter PlatForm"
                  key="PlatForm"
                />
              </>
            )}
            {userRoles.includes("manager") && (
              <Dropdown.Item
                onClick={() => window.open(config.managerPortalUri, "_blank")}
                icon="home"
                text="Manager PlatForm"
                key="PlatForm"
              />
            )}
            {userRoles.includes("serviceProfessional") && (
              <Dropdown.Item
                onClick={() => window.open(config.managerPortalUri, "_blank")}
                icon="wrench"
                text="Mantenance PlatForm"
                key="PlatForm"
              />
            )}
            <Dropdown.Item
              onClick={handleModalToggle}
              icon="sign out"
              text="Log Out"
              key="sign-out"
            />
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        false
      )}
    </>
  );
}

export default UserMenu;
