import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import "../SignUp.scss";
import FormView from "./FormView";
import { RENTER, ROUTES } from "../../../utils/constants";
import mixpanel from "mixpanel-browser";
class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = { active: 0, isRenterSignUp: RENTER };
  }
  componentDidMount() {
    mixpanel.track("Sign Up Action", {
      ndbId: "0",
      sub: "Sign Up Page Rendered",
    });
    let routeState;
    if (this.props.history.location.state) {
      localStorage.setItem(
        "routeState",
        JSON.stringify(this.props.history.location.state)
      );
      routeState = this.props.location.state;
    } else {
      routeState = localStorage.getItem("routeState");
      if (routeState) routeState = JSON.parse(routeState);
    }
    this.setState({
      isRenterSignUp: routeState?.isRenterSignUp || RENTER,
    });
  }

  isRenter = () => this.state.isRenterSignUp === RENTER;

  handleClick = (e, { value }) => {
    this.setState({ active: value });
  };
  render() {
    let { active, isRenterSignUp } = this.state;
    const SignUp = () => (
      <div className="signup-tabs">
        <ul>
          <li
            className={`${active === 0 && "active-tab"}`}
            value={0}
            onClick={() => this.setState({ active: 0 })}
          >
            <span className="counter"></span>
            <span className="tab-text">Basic Information</span>
          </li>
          <li
            className={`${active === 1 && "active-tab"}`}
            value={1}
            onClick={() => active === 2 && this.setState({ active: 1 })}
          >
            <span className="counter"></span>
            <span className="tab-text">Account Setup</span>
          </li>
          <li className={`${active === 2 && "active-tab"}`} value={2}>
            <span className="counter"></span>
            <span className="tab-text">Review</span>
          </li>
        </ul>
      </div>
    );
    return (
      <>
        <div className="main-container signin-container">
          <div className="signup-section">
            <div className="signup-left d-lg-flex">
              <div
                id="carouselExampleCaptions"
                className="carousel slide carousel-fade"
                data-ride="carousel"
              >
                <ol className="carousel-indicators">
                  <li
                    data-target="#carouselExampleCaptions"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li
                    data-target="#carouselExampleCaptions"
                    data-slide-to="1"
                  ></li>
                  <li
                    data-target="#carouselExampleCaptions"
                    data-slide-to="2"
                  ></li>
                  <li
                    data-target="#carouselExampleCaptions"
                    data-slide-to="3"
                  ></li>
                  <li
                    data-target="#carouselExampleCaptions"
                    data-slide-to="4"
                  ></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="content-wrap">
                      <img
                        src="/assets/img/Auth/bg-signin-left.jpg"
                        alt="Left bitmap"
                        className="full-section-image"
                      />
                      <NavLink
                        exact
                        to={ROUTES.LANDING}
                        activeClassName="active"
                        className="brand-image"
                      >
                        <img src="assets/img/Realty-Logos-05.png" alt="Logo" />
                      </NavLink>
                      <div className="text">
                        <p className="big">Welcome to a new life on leasing.</p>
                        <p className="small">
                          Find a new home, explore long-term furnished housing,
                          or dream big by browsing vacation spots.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="content-wrap">
                      <img
                        src="/assets/img/Auth/bg-signin-left.jpg"
                        alt="Left bitmap"
                        className="full-section-image"
                      />
                      <NavLink
                        exact
                        to={ROUTES.LANDING}
                        activeClassName="active"
                        className="brand-image"
                      >
                        <img src="assets/img/Realty-Logos-05.png" alt="Logo" />
                      </NavLink>
                      <div className="text">
                        <p className="big">
                          Renter empowerment for the modern age.
                        </p>
                        <p className="small">
                          We've worked tirelessly to provide access to the
                          services and brands we all love.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="content-wrap">
                      <img
                        src="/assets/img/Auth/bg-signin-left.jpg"
                        alt="Left bitmap"
                        className="full-section-image"
                      />
                      <NavLink
                        exact
                        to={ROUTES.LANDING}
                        activeClassName="active"
                        className="brand-image"
                      >
                        <img src="assets/img/Realty-Logos-05.png" alt="Logo" />
                      </NavLink>
                      <div className="text">
                        <p className="big">REM is your best fit.</p>
                        <p className="small">
                          We have created a new business model for properties
                          and a new lifestyle of empowerment.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="content-wrap">
                      <img
                        src="/assets/img/Auth/bg-signin-left.jpg"
                        alt="Left bitmap"
                        className="full-section-image"
                      />
                      <NavLink
                        exact
                        to={ROUTES.LANDING}
                        activeClassName="active"
                        className="brand-image"
                      >
                        <img src="assets/img/Realty-Logos-05.png" alt="Logo" />
                      </NavLink>
                      <div className="text">
                        <p className="big">
                          Enter a new era of rental management.
                        </p>
                        <p className="small">
                          Thanks to REM, you can now handle flexible leasing
                          opportunities and engage a supportive network.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="content-wrap">
                      <img
                        src="/assets/img/Auth/bg-signin-left.jpg"
                        alt="Left bitmap"
                        className="full-section-image"
                      />
                      <NavLink
                        exact
                        to={ROUTES.LANDING}
                        activeClassName="active"
                        className="brand-image"
                      >
                        <img src="assets/img/Realty-Logos-05.png" alt="Logo" />
                      </NavLink>
                      <div className="text">
                        <p className="big">A Marketplace of Value.</p>
                        <p className="small">
                          Win the hearts of more consumers.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="login-container">
              <form class="content">
                <div class="signup-top-section">
                  <h1>{`Sign Up as a ${
                    isRenterSignUp ? isRenterSignUp : "Renter"
                  }`}</h1>
                  <p class="signup-detail">
                    Let’s get started, please tell us about yourself
                  </p>
                  <SignUp />
                  <FormView
                    activeTab={active}
                    setCount={this.handleClick}
                    isRenterSignUp={isRenterSignUp}
                    history={this.props.history}
                    isRenter={this.isRenter}
                  />
                  {/* <p class="signup-text">Already have an account?&nbsp;<button onClick={() => this.props.history.push({
                            pathname: ROUTES.SIGNIN,
                            client: this.props.client,
                            isPropertyDetail: this.props.isPropertyDetail
                        })}
                        title="Sign Up">Sign In</button></p> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SignUpForm);
