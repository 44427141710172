import React, { Component } from "react";
import { withApollo } from "react-apollo";
import FooterMain from "../../components/Footer/FooterMain";
import Header from "../../components/Header";
import Newsletter from "../../components/Newsletter";
import { NavLink } from "react-router-dom";

class RentalAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div class="platform-products-wrapper privacy-wrapper rental-agreement-wrap">
          <Header />
          <div class="banner banner-service-provider">
            <div class="container text-center">
              <div class="row">
                <div class="col-12">
                  <h1>Rental Platform Agreement</h1>
                </div>
              </div>
            </div>
          </div>
          <section class="privacy-wrapper terms-wrapper">
            <div class="container">
              <div class="content-data">
                <p>
                  Welcome so the Leasera® Property Manager platform (“
                  <b>Leasera Platform</b>”). By using the applications feature,
                  the listings feature, the leasing feature, the payments
                  feature, or other features of the Leasera Platform, or by
                  accessing any content provided by Leasera, Inc. ( “
                  <b>Leasera</b>”), you agree to these Rental Platform Terms (“
                  <b>Platform Terms</b>”) as well as the Leasera Terms of Use (“
                  <b>Terms of Use</b>”), available at www.leasera.com/TOU, and
                  the Leasera Privacy Policy available
                  at www.leasera.com/privacy. If you do not agree to these
                  Platform Terms, Terms of Use, or the Leasera Privacy Policy,
                  please do not use the Leasera Platform.
                </p>
              </div>
              <ul>
                <li>
                  <h4>1. The Leasera Platform</h4>
                  <p>
                    {" "}
                    The Leasera Property Manager Platform allows users (“
                    <b>You</b>”) consisting of both rental managers (“
                    <b>Rental Managers</b>”) and renters (“<b>Renters</b>”), to
                    find properties and potential renters, sharing leasing
                    details, including allowing Rental Managers to publish their
                    available properties directly to Renters (each a “
                    <b>Listing</b>”), submit and accept rental applications,
                    create leases, and set up ongoing rental payments. The
                    Leasera Platform is offered by Leasera as a facilitation
                    service only. In providing the Leasera Platform services,
                    Leasera does not act as a broker, property manager, payment
                    processor, legal advisor, money transmitter, payment
                    manager, or credit reporting agency, and does not guarantee
                    any results from using the Leasera Platform. [Leasera does
                    not independently verify any Renter or Rental Manager, and
                    does not perform credit or background checks, and does not
                    make any representations or warranties as to the quality of
                    any Renter or Rental Manager or guarantee any results from
                    any background or credit check].{" "}
                  </p>
                </li>
                <li>
                  <h4>2. Accounts and Verification</h4>
                  <p>
                    To use the Leasera Platform, you must be a registered
                    Leasera user. You agree to only use the Leasera Platform in
                    connection with residential property rental transactions. To
                    use the Listing feature of the Leasera Platform you may be
                    required to verify your identity through SMS/text messaging
                    or by phone (See Section 13, Communications). To use certain
                    other features of the Leasera Platform, including the
                    identity verification, credit report, background check, and
                    payment services, you will be required to verify your
                    identity with our third-party vendors.{" "}
                  </p>
                </li>

                <li>
                  <h4>3. Use of the Leasera Platform </h4>
                  <p>By using the Leasera Platform, you agree to: </p>
                  <ul class="subchild-element">
                    <li>
                      <p>
                        Use the Leasera Platform in compliance with all
                        applicable laws, and all legal notice and disclosure
                        requirements as provided in TenantAlert and Heartland
                        Payment Systems agreements (as described below),
                        including, without limitation, the Fair Credit Reporting
                        Act and the Fair Housing Act;{" "}
                      </p>
                    </li>
                    <li>
                      <p>
                        Comply with the terms of all of Leasera’s third-party
                        vendor services (as described below), including
                        TenantAlert, and Heartland Payment Systems;
                      </p>
                    </li>
                    <li>
                      <p>
                        Use the Leasera Platform only on your own behalf, and
                        not transmit any information received through the
                        Leasera Platform to third parties;
                      </p>
                    </li>
                    <li>
                      <p>
                        Provide Leasera, upon Leasera’s request, with the
                        details of all transactions processed by you through the
                        Leasera Platform;
                      </p>
                    </li>
                    <li>
                      <p>
                        Use the Leasera Platform only in connection with
                        properties within the United States, and only using a
                        payment method issued in the United States;
                      </p>
                    </li>
                    <li>
                      <p>
                        Charge a Renter only as legally authorized under a
                        written agreement between you and a Renter;
                      </p>
                    </li>
                    <li>
                      <p>
                        Not use the Leasera Platform to transfer money in
                        violation of any money laundering laws, including the
                        Bank Secrecy Act, or any regulations of the US
                        Treasury’s Office of Foreign Assets Control or the
                        Federal Trade Commission;
                      </p>
                    </li>
                    <li>
                      <p>
                        Not attempt to process more than $10,000 in rent
                        payments per month through the Leasera Platform and
                        comply with and not attempt to circumvent any other
                        transaction limits set by Leasera;
                      </p>
                    </li>
                    <li>
                      <p>
                        Not use the income verification service available on the
                        Rental Platform for any purpose other than to assist you
                        in making a housing decision or require a Renter to
                        provide any banking, sensitive financial, or personally
                        identifiable information to you by any means other than
                        through the income verification service;
                      </p>
                    </li>
                    <li>
                      <p>
                        Not add any tax or other surcharge to rent amounts
                        specified in the Leasera Platform;
                      </p>
                    </li>
                    <li>
                      <p>
                        Not submit a request for payment through the Leasera
                        Platform for any fee incurred by a Renter at a time
                        prior to use of the Leasera Platform, or otherwise to
                        settle outstanding debts, or as otherwise prohibited by
                        local laws;
                      </p>
                    </li>
                    <li>
                      <p>
                        Not submit any false or fraudulent information through
                        the Leasera Platform, or otherwise use the Leasera
                        Platform for false or fraudulent purposes;
                      </p>
                    </li>
                    <li>
                      <p>
                        Not rely on any feature of the Leasera Platform in lieu
                        of legal advice from a licensed attorney;
                      </p>
                    </li>
                    <li>
                      <p>
                        Not use the Leasera Platform to discriminate against
                        anyone based on race, color, national origin, religion,
                        sex, disability, or the presence of children, or decline
                        or treat a Renter differently based on any other class
                        protected by the laws of your state and municipality
                        (e.g., sexual orientation, gender identity, military
                        status or marital status);
                      </p>
                    </li>
                    <li>
                      <p>
                        Not to share an application created through the Leasera
                        Platform (including any related credit reports or
                        background checks) with any third-party, or otherwise
                        provide any portion of an application outside of the
                        Leasera Platform; and
                      </p>
                    </li>
                    <li>
                      <p>
                        Not attempt to break, or encourage anyone else to break,
                        the above requirements.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>4. Payments </h4>
                  <ul class="subchild-element">
                    <li>
                      <span>Generally</span>
                      <p>
                        You may be required to pay fees to access certain
                        features of the Leasera Platform. All fees are in U.S.
                        dollars and are non-refundable. If Leasera changes the
                        fees for all or part of the Leasera Platform, including
                        by adding fees or charges, Leasera will provide you
                        advance notice of those changes. If you do not accept
                        the changes, Leasera may discontinue providing the
                        applicable part of the Leasera Platform to you.
                        Leasera’s third-party payment processors will charge the
                        payment method you specified at the time of purchase.{" "}
                      </p>
                    </li>
                    <li>
                      <span>Amounts</span>
                      <ul className="subsubchild-element">
                        <li>
                          <span>Listings Feature</span>
                          <p>
                            You will be charged for Listings in accordance with
                            Leasera’s pricing policies in effect at the time of
                            your listing (a “Listing Fee”). By posting a
                            Listing, you are responsible for paying Leasera the
                            Listing Fee regardless of whether: (a) you are set
                            up to receive contacts via the Leasera Platform; or
                            (b) there are errors in your information, or the
                            Listing information displayed on the Leasera
                            Platform. The Listing Fee will be charged
                            immediately upon your submission of a Listing (the
                            “Listing Date”) and every 7 days afterwards (each a
                            “Renewal Term”). The Renewal Terms will continue
                            until: (a) the Listing is terminated by either Party
                            (See Section 7); or (b) Leasera deactivates the
                            Listing. You understand that Leasera may remove any
                            Listing if you fail to pay Leasera the Listing Fee
                            for any Renewal Term or after the expiration of any
                            promotional period. You agree that any unpaid
                            Listing Fees will accrue interest at the rate of one
                            percent (1%) a week until paid, or the legal
                            maximum, whichever is less. Charges are exclusive of
                            applicable taxes. You are responsible for paying:
                            (a) all taxes and government charges; and (b)
                            reasonable expenses (including collection agency and
                            attorneys’ fees) Leasera incurs in collecting unpaid
                            amounts. Before you pay any Listing Fees, you will
                            have an opportunity to review and accept the Listing
                            Fees that you will be charged. All Listing Fees are
                            in U.S. Dollars and are non-refundable. Leasera may
                            change the Listing Fees, or any feature of the
                            Listing application, including by adding additional
                            fees, on a going forward basis at any time. Leasera
                            will charge the payment method you specify at the
                            time of purchase. If you change your payment method,
                            the new payment method will automatically become
                            your default payment method for every Renewal Term
                            going forward. You authorize Leasera to charge all
                            sums described in these Rentals User Terms, for the
                            services you select, to that payment method. You may
                            terminate any Listing at any time through (a) the
                            deactivation link sent to your email before the end
                            of each Renewal Term[; or (b) the “Deactivate
                            Listing” button found on the Listing’s page on the
                            Leasera Platform]. For more information on your
                            termination rights, email support@leasera.com. By
                            using the Listings application to publish your
                            Listing you grant Leasera a worldwide,
                            non-exclusive, perpetual, irrevocable, royalty-free
                            right and license (with the right to sublicense) to
                            host, store, transfer, display, perform, use,
                            reproduce, modify, and distribute any information or
                            photographs submitted with the Listing (the “Listing
                            Content”), in whole and in part, in any media
                            formats and through any media channels (now known or
                            hereafter developed). Leasera’s use of the Listing
                            Content may be without any compensation to you.{" "}
                          </p>
                        </li>
                        <li>
                          <span>Applications Feature</span>
                          <p>
                            [The applications feature is currently free for
                            Rental Managers. Renters pay a set fee for 30 days
                            (“Admin Fee”)], during which Renter can share their
                            application with any Rental Manager on the Leasera
                            Platform. To continue submitting applications,
                            Renters will have to pay an additional Admin Fee for
                            each subsequent 30-day period.
                          </p>
                        </li>
                        <li>
                          <span>Payments Feature</span>
                          <p>
                            The payments feature is currently free to Rental
                            Managers. Renters pay a set fee for using credit or
                            debit to pay rent as described in the Leasera
                            Platform, or can make payment via ACH for free. You
                            will not attempt to use the payments feature to
                            process any payments that are not associated with a
                            rental payment, including any trial or test payments
                            (as such amounts will not be refunded).{" "}
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span>Third-party Payment Facilitators</span>
                      <p>
                        You authorize Leasera and its third-party processors to
                        charge all fees as described in these Platform Terms,
                        for the Leasera Platform, to your chosen payment method.
                        If you pay any fees, other than the Listing Fee, with a
                        credit card, Leasera’s third-party payment processors
                        may seek pre-authorization of your credit card account
                        before your purchase to verify that the credit card is
                        valid and has the necessary funds or credit available to
                        cover your purchase. You acknowledge and authorize
                        Leasera’s third-party payment processors to charge your
                        credit card when you submit your Listing to the Leasera
                        Platform and for each Renewal Term. The Listings and
                        rentals applications features use Heartland Payments
                        Systems as a third-party payment processor. Rental
                        Managers using the rental payments feature are required
                        to set up a Heartland Payments account in order to
                        receive payments through the Leasera Platform. By making
                        or receiving payments through the Leasera Platform, you
                        grant Leasera and our third-party payment processors the
                        right, power, and authority to act on your behalf to
                        access and transmit your personal and financial
                        information from the relevant institution, and to charge
                        your account in the amounts specified in the Leasera
                        Platform. You agree to your personal and financial
                        information being transferred, stored, and processed by
                        our third-party payment processors.
                      </p>
                    </li>
                    <li>
                      <span>Chargebacks</span>
                      <p>
                        Except for the Listing Fee, Leasera does not hold any
                        money you transfer through the Leasera Platform and
                        Leasera is not responsible for unsuccessful
                        transmission, sourcing, or use of any funds. If a Renter
                        or Rental Manager’s bank or card issuer initiates a
                        reversal, chargeback, or payment dispute of a payment
                        made through the Leasera Platform, you authorize Leasera
                        and our third-party payment processors to reverse or
                        otherwise claim the funds from your account. Leasera may
                        also initiate a reversal, or take other actions we
                        determine to be appropriate, if, in Leasera’s reasonable
                        opinion, fraud or abuse of the Leasera Platform has
                        occurred. Additionally, Leasera may choose to implement
                        a “charge back” or “reversal” fee in the case of
                        unsuccessful payment, in which case Leasera will provide
                        you prior notice of the amount to be charged.{" "}
                      </p>
                    </li>
                    <li>
                      <span>No Refunds/No Guarantees</span>
                      <p>
                        Leasera does not provide any refunds, and does not
                        guarantee any result using the Leasera Platform,
                        including, but not limited to, receiving an application,
                        an application being accepted, or a Rental Manager
                        electing to accept payments through the Leasera
                        Platform.{" "}
                      </p>
                    </li>
                    <li>
                      <span>Additional Fees</span>
                      <p>
                        As a Rental Manager, you agree not to charge any
                        application or payment processing fee to Renters
                        submitting applications or payments through the Leasera
                        Platform. Additionally, you agree to not charge for any
                        amounts other than a Renter’s monthly rent, monthly
                        utilities, deposit, or other amounts as specifically
                        allowed by the Leasera Platform.{" "}
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>5. Third-party Authorizations </h4>
                  <p>
                    By requesting a credit report or background check, you
                    authorize and direct TenantAlert to obtain “consumer
                    reports” about you on a recurring basis, including
                    information concerning your character, mode of living,
                    credit history and standing, and criminal records. You
                    understand that TenantAlert will obtain your credit report
                    and credit score(s) and will obtain information about your
                    criminal history, and may take steps to verify the
                    information obtained about you. You understand that no
                    report will be made available to any potential Rental
                    Manager or other third-party until you receive a copy and
                    are afforded an opportunity to correct any factually
                    inaccurate information and to authorize release to specific
                    potential Rental Managers or other third parties. You may
                    request a paper copy of your credit report your background
                    check by contacting TenantAlert via the contact method
                    listed in Section 6 below.{" "}
                  </p>
                </li>
                <li>
                  <h4>6. Third-party Terms. </h4>
                  <ul className="subchild-element">
                    <li>
                      <span>TenantAlert</span>
                      <p>
                        The rental applications feature allows users to use the
                        services of TenantAlert, our third-party background
                        check partner, to submit an application and background
                        check and credit check to a Rental Manager through the
                        Leasera Platform. If you are a Renter using the
                        TenantAlert services, you authorize Leasera to obtain
                        your background check and credit check report, including
                        criminal and eviction history, and to share that
                        information with Rental Managers that you submit an
                        application to. If you are a Rental Manager using the
                        TenantAlert services, you agree to use the background
                        check and credit reports in compliance with law, and
                        agree to the TenantAlert Underwriting Terms and
                        Conditions, available at:{" "}
                        <NavLink
                          exact
                          to="https://member.tenantalert.com/underwriting-terms-conditions"
                          activeClassName="active"
                        >
                          https://member.tenantalert.com/underwriting-terms-conditions
                        </NavLink>
                      </p>
                    </li>
                    <li>
                      <span>Heartland Payments.</span>
                      <p>
                        The rental payments feature allows you to use Heartland
                        Payments to make or receive payments to other users, and
                        may include additional processing or other fees detailed
                        when you choose to use Heartland Payments. Your use of
                        Heartland Payments is subject to the Heartland Payments
                        Connected Account Agreement, available at{" "}
                        <NavLink exact to="https://" activeClassName="active">
                          https://
                        </NavLink>{" "}
                        Additionally, by using Heartland Payments, you agree not
                        to use Heartland Payments (and the Leasera Platform
                        generally) for any Prohibited Business purposes, as
                        listed at{" "}
                        <NavLink exact to="https://" activeClassName="active">
                          https://
                        </NavLink>{" "}
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>7. Termination</h4>
                  <p>
                    You may terminate your account at any time by
                    contacting support@Leasera.com. You may cancel any rent
                    payment not already in process in your account dashboard. If
                    you terminate your account, you remain obligated to pay all
                    outstanding fees, if any, incurred prior to termination
                    relating to your use of the Leasera Platform. If you violate
                    any provision of these Rental User Terms, your permission
                    from Leasera to use the Leasera Platform will terminate
                    automatically. In addition, Leasera may in its sole
                    discretion terminate your account on the Leasera Platform or
                    suspend or terminate your access to the Leasera Platform at
                    any time for any reason, with or without notice, including
                    if: (i) you fail to pay any amount to Leasera when due; (ii)
                    we determine that you pose a financial, reputational or
                    regulatory risk to Leasera or any third party; or (iii) we
                    are required to do so by any third party. Leasera may alter,
                    suspend or discontinue the Leasera Platform or any portion
                    of the Leasera Platform without notice. Any termination by a
                    Renter or Rental Manager of their account will not terminate
                    any underlying agreement entered into between a Renter and
                    Rental Manager, or a Renter or Rental Manager and a
                    third-party, and both Renter and Rental Manager will remain
                    bound by the terms of the underlying agreement between the
                    parties. Leasera will not be bound by the terms of any lease
                    or other agreement entered into between a Renter and Rental
                    Manager, and will not be liable for any breach of such
                    agreement by either a Renter or Rental Manager. Leasera will
                    not be liable whatsoever on account of any change to the
                    Leasera Platform or any suspension or termination of your
                    access to or use of the Leasera Platform. Leasera will
                    remove any Listing that it determines in its sole discretion
                    (a) violates any third party’s rights; (b) violates the
                    Digital Millennium Copyright Act (you can access the DMCA
                    here); (c) results in the publication of any material that
                    Leasera deems inappropriate; or (d) results in your
                    publication of any content that violates Fair Housing Laws.
                    Leasera will notify you of, and stop charging you the
                    Listing Fee for, any removed Listing. Leasera reserves the
                    right to change these Platform Terms at any time in its sole
                    discretion on a going-forward basis. In the event Leasera
                    changes these Platform Terms, Leasera will make reasonable
                    efforts to provide notice to users. Any changes will be
                    effective immediately upon posting the revised version of
                    these Platform Terms to Leasera's properties. Your continued
                    use of the Leasera Platform after notice of such changes
                    will constitute acceptance of and agreement to any such
                    changes. You further waive any right you may have to receive
                    specific notice of such changes to these Platform Terms. You
                    are responsible for regularly reviewing these Platform
                    Terms. In the event that this Agreement is terminated,
                    Leasera will not be obligated to return any material or
                    information to you related to a terminated Listing. Leasera
                    will not have any liability to you or any other person for
                    the removal of information concerning your account or any
                    Listing.{" "}
                  </p>
                </li>
                <li>
                  <h4>8.Indemnification </h4>
                  <p>
                    You agree to indemnify, defend, and hold harmless Leasera,
                    its affiliates, and their respective directors, officers,
                    employees, and agents from any and all claims and demands
                    made by any third-party due to or arising out of: (a) your
                    access to or use of the Leasera Platform; (b) your breach of
                    these Rental User Terms or the Leasera Terms of Use; (c)
                    your violation of any law or the rights of a third-party;
                    and (d) any dispute or issue between you and any
                    third-party, including any other Renter or Rental Manager
                    user of the Leasera Platform or any third-party vendor;
                    Leasera reserves the right, at its own expense, to assume
                    the exclusive defense and control of any matter otherwise
                    subject to indemnification by you, and in that case, you
                    agree to corporate with Leasera’s defense of that claim.{" "}
                  </p>
                </li>
                <li>
                  <h4>9. No Warranties</h4>
                  <p>
                    SUBJECT TO THE DISCLAIMER OF WARRANTIES IN THE LEASERA TERMS
                    OF USE, LEASERA PROVIDES THE LEASERA PLATFORM "AS IS," "WITH
                    ALL FAULTS" AND "AS AVAILABLE," AND THE ENTIRE RISK AS TO
                    SATISFACTORY QUALITY, PERFORMANCE, ACCURACY, AND EFFORT IS
                    WITH YOU.{" "}
                  </p>
                </li>
                <li>
                  <h4>10. Limitation of Liability/Exclusive Remedy</h4>
                  <p>
                    {" "}
                    IN NO EVENT WILL LEASERA OR ANY OF ITS AFFILIATES BE LIABLE
                    FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR
                    PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS,
                    GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF,
                    BASED ON, OR RESULTING FROM THESE PLATFORM TERMS OR YOUR USE
                    OR ACCESS OR INABILITY TO USE OR ACCESS THE LEASERA PLATFORM
                    OR ANY MATERIALS OBTAINED ON OR THROUGH THE LEASERA
                    PLATFORM, WHETHER BASED ON (A) BREACH OF CONTRACT, (B)
                    BREACH OF WARRANTY, (C) NEGLIGENCE, OR (D) ANY OTHER CAUSE
                    OF ACTION, EVEN IF LEASERA HAS BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES. THE AGGREGATE LIABILITY OF
                    LEASERA AND ANY OF ITS AFFILIATE TO YOU FOR ALL CLAIMS
                    ARISING OUT OF OR RELATING TO THE USE OF OR INABILITY TO USE
                    ANY PORTION OF THE LEASERA PLATFORM OR OTHERWISE UNDER THESE
                    PLATFORM TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS
                    LIMITED TO THE GREATER OF: (I) THE AMOUNT YOU HAVE PAID TO
                    LEASERA FOR THE LEASERA PLATFORM (NOT INCLUDING ANY RENTAL
                    PAYMENTS TRANSMITTED THROUGH THE LEASERA PLATFORM) IN THE 12
                    MONTHS PRIOR TO THE EVENTS OR CIRCUMSTANCES GIVING RISE TO
                    THE CLAIMS; OR (II) $100. SOME JURISDICTIONS DO NOT ALLOW
                    THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
                    DAMAGES. ACCORDINGLY, THE ABOVE LIMITATIONS MAY NOT APPLY TO
                    YOU. EACH PROVISION OF THESE PLATFORM TERMS THAT PROVIDES
                    FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR
                    EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE
                    RISKS BETWEEN THE PARTIES UNDER THESE TERMS. THIS ALLOCATION
                    IS AN ESSENTIAL ELEMENT OF THE AGREEMENT OF THE PARTIES. THE
                    LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED
                    REMEDY FAILS ITS ESSENTIAL PURPOSE.{" "}
                  </p>
                </li>
                <li>
                  <h4>11. Choice of Law; Disputes. </h4>
                  <p>
                    These Platform Terms are governed by the laws of the State
                    of Washington, without giving effect to its conflict of laws
                    provisions. You agree to submit to the personal and
                    exclusive jurisdiction and venue in the state and federal
                    courts sitting in King County, Washington for any and all
                    disputes, claims and actions arising from or in connection
                    with the Leasera Platform or these Platform Terms. Leasera
                    operates the Leasera Platform from our offices in
                    Washington, and we make no representation that the Leasera
                    Platform is appropriate or available for use in other
                    locations.{" "}
                  </p>
                </li>
                <li>
                  <h4>12. General.</h4>
                  <p>
                    You agree not to export from anywhere any part of the
                    Leasera Platform provided to you or any direct product
                    thereof except in compliance with, and with all licenses and
                    approvals required under, applicable export laws, rules and
                    regulations. All Leasera Platform used by the U.S.
                    Government are provided with the commercial license rights
                    described herein. These Platform Terms may only be amended
                    by a written agreement signed by authorized representatives
                    of the parties to these Platform Terms. If any part of these
                    Platform Terms is determined to be invalid or unenforceable,
                    then the invalid or unenforceable provision will be replaced
                    with a valid, enforceable provision that most closely
                    matches the intent of the original provision and the
                    remainder of these Platform Terms will continue in effect.
                    The section titles in these Platform Terms are solely used
                    for the convenience of the parties and have no legal or
                    contractual significance. Leasera may assign this Agreement,
                    in whole or in part, at any time with or without notice to
                    you. You may not assign these Platform Terms, or assign,
                    transfer or sublicense your rights, if any, in the Leasera
                    Platform. Leasera's failure to act with respect to a breach
                    by you or others does not waive its right to act with
                    respect to subsequent or similar breaches. Except as
                    expressly stated herein, these Platform Terms, and all
                    expressly incorporated agreements, constitute the entire
                    agreement between you and Leasera with respect to the
                    Leasera Platform and supersede all prior or contemporaneous
                    communications of any kind between you and Leasera with
                    respect to the Leasera Platform. The following sections of
                    these Platform Terms shall survive any termination of these
                    Platform Terms: 2 - 14.{" "}
                  </p>
                </li>
                <li>
                  <h4>13. Communications.</h4>
                  <p>
                    The Rental Platform services may provide you with web forms,
                    links, or contact information, including phone numbers, that
                    can connect you with Leasera or our third-party vendors.
                    Communications through these methods may be routed through a
                    third-party service ("Communications Service"). Calls may be
                    recorded or monitored for quality assurance and customer
                    service purposes. You will be notified at the beginning of a
                    call if it may be recorded or monitored. You consent to such
                    recording and monitoring by Leasera or the Communications
                    Service. Leasera also uses the Communications Service to
                    track phone calls and text messages so that Leasera can
                    access certain details about the contact. As part of this
                    process, Leasera and the Communications Service will receive
                    in real time, and store, data about your call or text
                    message, including the date and time of the call or text
                    message, your phone number, and the content of the text
                    message. You consent to these practices by Leasera and the
                    Communications Service. The information is subject to the
                    Privacy Policy.{" "}
                  </p>
                </li>
                <li>
                  <h4>14. Contact.</h4>
                  <p>
                    he Leasera Platform is offered by Leasera, Inc., located at
                    1411 4TH AVE NE, SEATTLE, WA, 98101. You may contact Leasera
                    by sending correspondence to that @zi or emailing
                    info@leasera.com.{" "}
                  </p>
                </li>
                <li>
                  <h4>15. Indemnification </h4>
                  <p>
                    You agree to defend, indemnify and hold harmless Company,
                    its affiliates, licensors and service providers, and its and
                    their respective officers, directors, employees,
                    contractors, agents, licensors, suppliers, successors and
                    assigns from and against any claims, liabilities, damages,
                    judgments, awards, losses, costs, expenses or fees
                    (including reasonable attorneys' fees) arising out of or
                    relating to Your violation of these Terms of Use or Your use
                    of the Website.{" "}
                  </p>
                </li>
                <li>
                  <h4>16. General Terms of Use </h4>
                  <ul class="subchild-element">
                    <li>
                      <span>Entire Agreement</span>
                      <p>
                        Except for any terms of service that you may agree to in
                        connection with the Company’s provision to you of
                        certain services, these Terms of Use and the Privacy
                        Policy constitute the entire agreement between You and
                        Company with respect to Your use of the Website, and
                        supersede all prior or contemporaneous understandings
                        and agreements, whether written or oral, with respect to
                        the subject matter contained herein. Your obligations to
                        indemnify Company, along with any other part of these
                        Terms of Use of Use which, by their nature should
                        survive termination of these Terms of Use or Your right
                        to use the Website, shall survive.{" "}
                      </p>
                    </li>
                    <li>
                      <span>No Assignment</span>
                      <p>
                        These Terms of Use and any rights or licenses granted to
                        You herein are personal to You, and may not be assigned
                        prior to obtaining Company’ written consent.{" "}
                      </p>
                    </li>
                    <li>
                      <span>Arbitration </span>
                      <p>
                        PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR
                        RIGHTS AND INCLUDES WAIVER OF A TRIAL BY JURY IN A COURT
                        AND OF THE ABILITY TO BRING A CLAIM ON BEHALF OF OTHERS.
                        Any disputes or claims arising from the use of the
                        Website, including disputes arising from or concerning
                        the interpretation, violation, invalidity,
                        non-performance, or termination of these Terms of Use,
                        will be resolved by binding arbitration under the
                        Federal Arbitration Act, federal arbitration law and
                        rules, and Washington state law. To the fullest extent
                        permitted by law: (a) no arbitration under these Terms
                        of Use shall be joined to any other arbitration; (b) no
                        class arbitration proceedings will be permitted; (c) no
                        finding or stipulation of fact in any other arbitration,
                        judicial or similar proceeding may be given preclusive
                        or collateral estoppel effect in any arbitration
                        hereunder (unless determined in another proceeding
                        between You and Company); and (d) no conclusion of law
                        in any other arbitration may be given any weight in any
                        arbitration hereunder (unless determined in another
                        proceeding between You and Company). Both parties will
                        be responsible for their respective arbitration fees.{" "}
                      </p>
                    </li>
                    <li>
                      <span>Governing Law and Jurisdiction </span>
                      <p>
                        These Terms of Use are governed by and construed in
                        accordance with the Federal Arbitration Act, federal
                        arbitration law, and the laws of the state of Washington
                        without giving effect to any choice or conflict of law
                        provision or rule. Notwithstanding the foregoing,
                        Company retains the right to bring any suit, action or
                        proceeding against You for breach of these Terms of Use
                        in Your country of residence or any other relevant
                        country.{" "}
                      </p>
                    </li>
                    <li>
                      <span>Injunctive Relief</span>
                      <p>
                        You agree that Company may bring suit in any court of
                        competent jurisdiction in order to enjoin infringement
                        or other misuse of Company’s or its affiliates or
                        licensor’s intellectual property rights.{" "}
                      </p>
                    </li>
                    <li>
                      <span>Limitation on Time to File Claims</span>
                      <p>
                        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF
                        OR RELATING TO THESE TERMS OR THE WEBSITE MUST BE
                        COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
                        ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
                        PERMANENTLY BARRED (if applicable law prohibits a one
                        (1) year limitation period for asserting claims, the
                        claim must be asserted within the shortest time period
                        in excess of one (1) year that is permitted by
                        applicable law).{" "}
                      </p>
                    </li>
                    <li>
                      <span>Waiver and Severability</span>
                      <p>
                        No waiver by Company of any term or condition set forth
                        in these Terms of Use shall be deemed a further or
                        continuing waiver of such term or condition or a waiver
                        of any other term or condition, and any failure by
                        Company to assert a right or provision under these Terms
                        of Use shall not constitute a waiver of such right or
                        provision. If any provision of these Terms of Use is
                        held by a court or other tribunal of competent
                        jurisdiction to be invalid, illegal or unenforceable for
                        any reason, such provision shall be eliminated or
                        limited to the minimum extent such that the remaining
                        provisions of the Terms of Use of Use will continue in
                        full force and effect.{" "}
                      </p>
                    </li>
                    <li>
                      <span>Notices, Questions or Concerns</span>
                      <p>
                        All notices, comments, requests for technical support,
                        and other communications relating to the Website should
                        be directed to{" "}
                        <NavLink
                          exact
                          to="info@re-ally.com"
                          activeClassName="active"
                        >
                          info@re-ally.com
                        </NavLink>
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </section>
          <Newsletter />
          <FooterMain />
        </div>
      </>
    );
  }
}

export default withApollo(RentalAgreement);
