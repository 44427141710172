import React from "react";
import { Card, Image } from "semantic-ui-react";
import PropertyBeds from "../../components/PropertyBeds";
import "react-datetime/css/react-datetime.css";
import { Logger } from "../../utils";
import { useBooking } from "../../context/booking-context";

const PropertyCard = ({ data, setUrl, handleRedirect }) => {
  const {
    prices,
    baths,
    photos,
    name,
    addresses,
    totalUnits,
    beds,
    sizes,
    address,
    hotelId,
  } = data;
  const finalAddress = hotelId !== null ? address : addresses[0];
  const url = JSON.parse(photos);
  const bookingCtx = useBooking();

  // Logger("PropertyCard").log("data", data);

  return (
    <Card fluid className="property-card-container">
      {hotelId !== null ? (
        <>
          <Card.Content
            onClick={() => {
              handleRedirect(data, "short");
              bookingCtx.setSelectedProperty(data);
            }}
          >
            <div className="image-wrapper">
              <Image
                floated="left"
                size="small"
                src={
                  url && url[0] ? url[0] : "assets/img/no-image-available.jpg"
                }
              />
            </div>
            <div className="content-wrapper">
              <Card.Header>{name}</Card.Header>
              <Card.Meta className="meta-address">{finalAddress}</Card.Meta>
              <Card.Meta className="rental-service-col">
                <div className="service-label">Short term rental</div>
                <div className="gift-point-wrap">
                  <div className="gift-point-icon">
                    <img
                      src="../../assets/img/gift-icon.svg"
                      alt="gift points"
                    />
                  </div>
                  <div className="gift-popup-wrap">
                    <img
                      src="../../assets/img/gift-colored-icon.svg"
                      alt="gift points"
                    />
                    <span>Get 500 Loyalty Points</span>
                  </div>
                </div>
              </Card.Meta>
              <Card.Meta className="price-txt">
                ${data.totalPrices.gte} <strong>|</strong> {totalUnits} units
              </Card.Meta>
            </div>
          </Card.Content>
        </>
      ) : (
        <Card.Content onClick={() => handleRedirect(data, "long")}>
          <div className="image-wrapper">
            <Image
              floated="left"
              size="small"
              src={url && url[0] ? url[0] : "assets/img/no-image-available.jpg"}
            />
          </div>
          <div className="content-wrapper">
            <Card.Header>
              {name}
              {/* <span>Date Listed: {moment(updatedAt).format('D MMM YYYY')}</span> */}
            </Card.Header>
            {addresses.length > 0 && (
              <Card.Meta className="meta-address">
                {(
                  addresses[0].streetOne +
                  " " +
                  addresses[0].city +
                  " " +
                  addresses[0].state +
                  " " +
                  addresses[0].zip
                ).replaceAll("undefined", " ")}
              </Card.Meta>
            )}
            <Card.Meta className="meta-details">
              <PropertyBeds bedrooms={beds} bathrooms={baths} sqft={sizes} />
            </Card.Meta>
            <Card.Meta className="rental-service-col">
              <div className="service-label">Long term rental</div>
              <div className="gift-point-wrap">
                <div className="gift-point-icon">
                  <img src="../../assets/img/gift-icon.svg" alt="gift points" />
                </div>
                <div className="gift-popup-wrap">
                  <img
                    src="../../assets/img/gift-colored-icon.svg"
                    alt="gift points"
                  />
                  <span>Get 500 Loyalty Points</span>
                </div>
              </div>
            </Card.Meta>
            <Card.Meta className="price-txt">
              ${prices && prices[0]} - ${prices && prices[1]} <strong>|</strong>{" "}
              {totalUnits} units
            </Card.Meta>
          </div>
        </Card.Content>
      )}
    </Card>
  );
};
export default PropertyCard;
